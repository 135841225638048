export interface hh_mm {
  hours: number;
  mins: number;
}

let today = new Date();
let currentDate = new Date().getDate();
let currentMonth = new Date().getMonth() + 1;
let currentYear = new Date().getFullYear();
let currentHrs = new Date().getHours();
let currentMins = new Date().getMinutes();

function setCurrentDateTime() {
  today = new Date();
  currentDate = new Date().getDate();
  currentMonth = new Date().getMonth() + 1;
  currentYear = new Date().getFullYear();
  currentHrs = new Date().getHours();
  currentMins = new Date().getMinutes();
}

export function checkNull(value: any) {
  if (value != null && value != undefined && value != "") {
    return true;
  } else {
    return false;
  }
}
export function checkFromDateToDate(fromDate, todate) {
  setCurrentDateTime();
  let from = new Date(fromDate);
  let to = new Date(todate);
  if (from.getFullYear() <= to.getFullYear()) {
    if (from.getFullYear() == to.getFullYear()) {
      if (from.getMonth() + 1 <= to.getMonth() + 1) {
        if (from.getMonth() + 1 == to.getMonth() + 1) {
          if (from.getDate() <= to.getDate()) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function returnDateString(data: any) {
  let date = new Date(data);
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export function FormattedDate(data: any) {
  let date = new Date(data);
  return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
}

function convertTime(time: string): hh_mm {
  const $times = time.split(":");
  return { hours: Number($times[0]), mins: Number($times[1]) };
}

function checkCurrentTime(time: hh_mm) {
    setCurrentDateTime();
  const currentHrs = new Date().getHours();
  const currentMins = new Date().getMinutes();

  if (time.hours >= currentHrs) {
    if (time.hours == currentHrs) {
      if (time.mins >= currentMins) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function checkTodayCurrentTime(time: hh_mm, backingHrs) {
    setCurrentDateTime();
  const currentHrs = new Date().getHours() + backingHrs;
  const currentMins = new Date().getMinutes();

  if (time.hours >= currentHrs) {
    if (time.hours == currentHrs) {
      if (time.mins >= currentMins) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return false;
  }
}

export function checkValidDeliveryDate(fDate: any) {
    setCurrentDateTime();
  const currentDate = new Date().getDate();
  const fromDate = new Date(fDate).getDate();

  const currentMonth = new Date().getMonth() + 1;
  const fromMonth = new Date(fDate).getMonth() + 1;

  const currentYear = new Date().getFullYear();
  const fromYear = new Date(fDate).getFullYear();

  if (fromYear >= currentYear) {
    if (fromYear == currentYear) {
      if (fromMonth >= currentMonth) {
        if (fromMonth == currentMonth) {
          if (fromDate >= currentDate) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return false;
  }
}

export function checkValidDeliveryTime2(
  fDate: any,
  ftime: any,
  data: any
): { res: boolean; message?: string } {
    setCurrentDateTime();
  const current = new Date();
  const selectedDate = new Date(fDate).getDate();
  const currentDate = new Date().getDate();
  const selectedMonth = new Date(fDate).getMonth() + 1;
  const currentMonth = new Date().getMonth() + 1;
  const selectedYear = new Date(fDate).getFullYear();
  const currentYear = new Date().getFullYear();
  const currentHrs = new Date().getHours();
  const currentMins = new Date().getMinutes();

  const backingHrs = checkNull(data?.backingTime) ? Number(data?.backingTime) : null;
  const toTime: hh_mm = checkNull(data?.cutOffTime)
    ? convertTime(data?.cutOffTime)
    : checkNull(data?.toTime)
    ? convertTime(data?.toTime)
    : null;
  const fromTime: hh_mm = checkNull(data?.fromTime)
    ? convertTime(data?.fromTime)
    : null;
  const selectedTime: hh_mm = convertTime(ftime);
  const deliveryTimeName = checkNull(data?.deliveryName) ? data?.deliveryName : null;
  // return
  /************************************If the product has zero hours for delivery and its in stock********************************/
  if (backingHrs == 0 || backingHrs == null) {
    if (checkValidDeliveryDate(fDate)) {
      if (returnDateString(new Date()) == returnDateString(fDate)) {
        if (checkCurrentTime(selectedTime)) {
          if (selectedTime.hours <= toTime.hours) {
            if (selectedTime.hours == toTime.hours) {
              if (selectedTime.mins <= toTime.mins) {
                return { res: true };
              } else {
                return {
                  res: false,
                  message: `Delivery can be accepted from ${currentHrs}:${currentMins} to ${
                    toTime.hours
                  }:${toTime.mins == 0 ? "00" : toTime.mins}`,
                };
              }
            } else {
              return { res: true };
            }
          } else {
            return {
              res: false,
              message: `Delivery can be accepted from ${currentHrs}:${currentMins} to ${
                toTime.hours
              }:${toTime.mins == 0 ? "00" : toTime.mins}`,
            };
            // return { res: false, message: `Delivery can be accepted from ${fromTime.hours}:${fromTime.mins == 0 ? '00' : fromTime.mins} to ${toTime.hours}:${toTime.mins == 0 ? '00' : toTime.mins}` }
          }
        } else {
          return {
            res: false,
            message: `Delivery can be accepted from ${currentHrs}:${currentMins} to ${
              toTime.hours
            }:${toTime.mins == 0 ? "00" : toTime.mins}`,
          };
        }
      } else {
        //pending
        if (selectedTime.hours >= fromTime.hours) {
          if (selectedTime.hours == fromTime.hours) {
            if (selectedTime.mins >= fromTime.mins) {
              if (selectedTime.hours <= toTime.hours) {
                if (selectedTime.hours == toTime.hours) {
                  if (selectedTime.mins <= toTime.mins) {
                    return { res: true };
                  } else {
                    return {
                      res: false,
                      message: `Delivery can be accepted from ${fromTime.hours}:${
                        fromTime.mins == 0 ? "00" : fromTime.mins
                      } to ${toTime.hours}:${toTime.mins == 0 ? "00" : toTime.mins}`,
                    };
                  }
                } else {
                  return { res: true };
                }
              } else {
                return {
                  res: false,
                  message: `Delivery can be accepted from ${fromTime.hours}:${
                    fromTime.mins == 0 ? "00" : fromTime.mins
                  } to ${toTime.hours}:${toTime.mins == 0 ? "00" : toTime.mins}`,
                };
              }
            } else {
              return {
                res: false,
                message: `Delivery can be accepted from ${fromTime.hours}:${
                  fromTime.mins == 0 ? "00" : fromTime.mins
                } to ${toTime.hours}:${toTime.mins == 0 ? "00" : toTime.mins}`,
              };
            }
          } else {
            if (selectedTime.hours <= toTime.hours) {
              if (selectedTime.hours == toTime.hours) {
                if (selectedTime.mins <= toTime.mins) {
                  return { res: true };
                } else {
                  return {
                    res: false,
                    message: `Delivery can be accepted from ${fromTime.hours}:${
                      fromTime.mins == 0 ? "00" : fromTime.mins
                    } to ${toTime.hours}:${toTime.mins == 0 ? "00" : toTime.mins}`,
                  };
                }
              } else {
                return { res: true };
              }
            } else {
              return {
                res: false,
                message: `Delivery can be accepted from ${fromTime.hours}:${
                  fromTime.mins == 0 ? "00" : fromTime.mins
                } to ${toTime.hours}:${toTime.mins == 0 ? "00" : toTime.mins}`,
              };
            }
          }
        } else {
          return {
            res: false,
            message: `Delivery can be accepted from ${fromTime.hours}:${
              fromTime.mins == 0 ? "00" : fromTime.mins
            } to ${toTime.hours}:${toTime.mins == 0 ? "00" : toTime.mins}`,
          };
        }
      }
    } else {
      return { res: false, message: "Delivery date cannot be the past date!" };
    }
  } else if (backingHrs > 0 && backingHrs <= 8) {
    if (checkValidDeliveryDate(fDate)) {
      /************************If selcted date and current date is equal*********************/
      if (returnDateString(fDate) == returnDateString(current)) {
        checkTodayCurrentTime(selectedTime, backingHrs);

        if (checkCurrentTime(selectedTime)) {
          if (selectedTime.hours >= fromTime.hours + backingHrs) {
            if (selectedTime.hours == fromTime.hours + backingHrs) {
              if (selectedTime.mins >= currentMins) {
                if (selectedTime.hours <= toTime.hours) {
                  if (selectedTime.hours == toTime.hours) {
                    if (selectedTime.mins <= toTime.mins) {
                      return { res: true };
                    } else {
                      return {
                        res: false,
                        message: `We need atleast ${backingHrs}${
                          backingHrs > 1 ? "hours" : "hour"
                        } to bake this product!So kindly select time before ${
                          toTime.hours
                        }:${toTime.mins == 0 ? "00" : toTime.mins}`,
                      };
                    }
                  } else {
                    return { res: true };
                  }
                } else {
                  return {
                    res: false,
                    message: `We need atleast ${backingHrs}${
                      backingHrs > 1 ? "hours" : "hour"
                    } to bake this product!So kindly select time before ${
                      toTime.hours
                    }:${toTime.mins == 0 ? "00" : toTime.mins}`,
                  };
                }
              } else {
                return {
                  res: false,
                  message: `Delivery can be accepted from ${
                    currentHrs + backingHrs
                  }:${currentMins} to ${toTime.hours}:${
                    toTime.mins == 0 ? "00" : toTime.mins
                  }`,
                };
              }
            } else {
              // pending for the different hours
              if (selectedTime.hours <= toTime.hours) {
                if (selectedTime.hours == toTime.hours) {
                  if (selectedTime.mins <= toTime.mins) {
                    return { res: true };
                  } else {
                    return {
                      res: false,
                      message: ` We need atleast ${backingHrs}${
                        backingHrs > 1 ? "hours" : "hour"
                      } to bake this product!So kindly select time before ${
                        toTime.hours
                      }:${toTime.mins == 0 ? "00" : toTime.mins}`,
                    };
                  }
                } else {
                  return { res: true };
                }
              } else {
                return {
                  res: false,
                  message: ` We need atleast ${backingHrs}${
                    backingHrs > 1 ? "hours" : "hour"
                  } to bake this product!So kindly select time before ${
                    toTime.hours - backingHrs
                  }:${toTime.mins == 0 ? "00" : toTime.mins}`,
                };
              }
            }
          } else {
            return {
              res: false,
              message: `Delivery can be accepted from ${
                currentHrs + backingHrs
              }:${currentMins} to ${toTime.hours}:${
                toTime.mins == 0 ? "00" : toTime.mins
              }`,
            };
          }
        } else {
          return { res: false, message: `Delivery time cannot be the past time!` };
        }
      } else {
        /**********************************Other Dates******************************* */
        if (selectedTime.hours >= fromTime.hours) {
          if (selectedTime.hours == fromTime.hours) {
            if (selectedTime.mins >= fromTime.mins) {
              if (selectedTime.hours <= toTime.hours) {
                if (selectedTime.hours == toTime.hours) {
                  if (selectedTime.mins <= toTime.mins) {
                    return { res: true };
                  } else {
                    return {
                      res: false,
                      message: `Delivery can be accepted from ${fromTime.hours}:${
                        fromTime.mins == 0 ? "00" : fromTime.mins
                      } to ${toTime.hours}:${toTime.mins == 0 ? "00" : toTime.mins}`,
                    };
                  }
                } else {
                  return { res: true };
                }
              } else {
                return {
                  res: false,
                  message: `Delivery can be accepted from ${fromTime.hours}:${
                    fromTime.mins == 0 ? "00" : fromTime.mins
                  } to ${toTime.hours}:${toTime.mins == 0 ? "00" : toTime.mins}`,
                };
              }
            } else {
              return {
                res: false,
                message: `Delivery can be accepted from ${fromTime.hours}:${
                  fromTime.mins == 0 ? "00" : fromTime.mins
                } to ${toTime.hours}:${toTime.mins == 0 ? "00" : toTime.mins}`,
              };
            }
          } else {
            if (selectedTime.hours <= toTime.hours) {
              if (selectedTime.hours == toTime.hours) {
                if (selectedTime.mins <= toTime.mins) {
                  return { res: true };
                } else {
                  return {
                    res: false,
                    message: `Delivery can be accepted from ${fromTime.hours}:${
                      fromTime.mins == 0 ? "00" : fromTime.mins
                    } to ${toTime.hours}:${toTime.mins == 0 ? "00" : toTime.mins}`,
                  };
                }
              } else {
                return { res: true };
              }
            } else {
              return {
                res: false,
                message: `Delivery can be accepted from ${fromTime.hours}:${
                  fromTime.mins == 0 ? "00" : fromTime.mins
                } to ${toTime.hours}:${toTime.mins == 0 ? "00" : toTime.mins}`,
              };
            }
          }
        } else {
          return {
            res: false,
            message: `Delivery can be accepted from ${fromTime.hours}:${
              fromTime.mins == 0 ? "00" : fromTime.mins
            } to ${toTime.hours}:${toTime.mins == 0 ? "00" : toTime.mins}`,
          };
        }
      }
    } else {
      return { res: false, message: "Delivery date cannot be the past date!" };
    }
  } else if (backingHrs >= 24) {
    // this is only if the baking time is 24 hours and above
    if (checkValidDeliveryDate(fDate)) {
      let days = Number(Math.ceil(backingHrs / 24));

      if (
        checkFromDateToDate(new Date().setDate(new Date().getDate() + days), fDate)
      ) {
        if (
          returnDateString(new Date().setDate(new Date().getDate() + days)) ==
          returnDateString(fDate)
        ) {
          if (checkCurrentTime(selectedTime)) {
            if (selectedTime.hours >= fromTime.hours) {
              if (selectedTime.hours == fromTime.hours) {
                if (selectedTime.mins >= fromTime.mins) {
                  if (selectedTime.hours <= toTime.hours) {
                    if (selectedTime.hours == toTime.hours) {
                      if (selectedTime.mins <= toTime.mins) {
                        return { res: true };
                      } else {
                        return {
                          res: false,
                          message: `Delivery can be accepted from ${
                            fromTime.hours
                          }:${fromTime.mins == 0 ? "00" : fromTime.mins} to ${
                            toTime.hours
                          }:${toTime.mins == 0 ? "00" : toTime.mins}`,
                        };
                      }
                    } else {
                      return { res: true };
                    }
                  } else {
                    return {
                      res: false,
                      message: `Delivery can be accepted from ${fromTime.hours}:${
                        fromTime.mins == 0 ? "00" : fromTime.mins
                      } to ${toTime.hours}:${toTime.mins == 0 ? "00" : toTime.mins}`,
                    };
                  }
                } else {
                  return {
                    res: false,
                    message: `Delivery can be accepted from ${fromTime.hours}:${
                      fromTime.mins == 0 ? "00" : fromTime.mins
                    } to ${toTime.hours}:${toTime.mins == 0 ? "00" : toTime.mins}`,
                  };
                }
              } else {
                if (selectedTime.hours <= toTime.hours) {
                  if (selectedTime.hours == toTime.hours) {
                    if (selectedTime.mins <= toTime.mins) {
                      return { res: true };
                    } else {
                      return {
                        res: false,
                        message: `Delivery can be accepted from ${fromTime.hours}:${
                          fromTime.mins == 0 ? "00" : fromTime.mins
                        } to ${toTime.hours}:${
                          toTime.mins == 0 ? "00" : toTime.mins
                        }`,
                      };
                    }
                  } else {
                    return { res: true };
                  }
                } else {
                  return {
                    res: false,
                    message: `Delivery can be accepted from ${fromTime.hours}:${
                      fromTime.mins == 0 ? "00" : fromTime.mins
                    } to ${toTime.hours}:${toTime.mins == 0 ? "00" : toTime.mins}`,
                  };
                }
              }
            } else {
              return {
                res: false,
                message: `Delivery can be accepted from ${fromTime.hours}:${
                  fromTime.mins == 0 ? "00" : fromTime.mins
                } to ${toTime.hours}:${toTime.mins == 0 ? "00" : toTime.mins}`,
              };
            }
          } else {
            return {
              res: false,
              message: `We need atleast ${backingHrs} hours to bake this product! Delivery date can be on ${FormattedDate(
                new Date().setDate(new Date().getDate() + days)
              )} from ${currentHrs}: ${currentMins} to ${toTime.hours}: ${
                toTime.mins > 0 ? toTime.mins : "00"
              } `,
            };
          }
        } else {
          if (selectedTime.hours >= fromTime.hours) {
            if (selectedTime.hours == fromTime.hours) {
              if (selectedTime.mins >= fromTime.mins) {
                if (selectedTime.hours <= toTime.hours) {
                  if (selectedTime.hours == toTime.hours) {
                    if (selectedTime.mins <= toTime.mins) {
                      return { res: true };
                    } else {
                      return {
                        res: false,
                        message: `Delivery can be accepted from ${fromTime.hours}:${
                          fromTime.mins == 0 ? "00" : fromTime.mins
                        } to ${toTime.hours}:${
                          toTime.mins == 0 ? "00" : toTime.mins
                        }`,
                      };
                    }
                  } else {
                    return { res: true };
                  }
                } else {
                  return {
                    res: false,
                    message: `Delivery can be accepted from ${fromTime.hours}:${
                      fromTime.mins == 0 ? "00" : fromTime.mins
                    } to ${toTime.hours}:${toTime.mins == 0 ? "00" : toTime.mins}`,
                  };
                }
              } else {
                return {
                  res: false,
                  message: `Delivery can be accepted from ${fromTime.hours}:${
                    fromTime.mins == 0 ? "00" : fromTime.mins
                  } to ${toTime.hours}:${toTime.mins == 0 ? "00" : toTime.mins}`,
                };
              }
            } else {
              if (selectedTime.hours <= toTime.hours) {
                if (selectedTime.hours == toTime.hours) {
                  if (selectedTime.mins <= toTime.mins) {
                    return { res: true };
                  } else {
                    return {
                      res: false,
                      message: `Delivery can be accepted from ${fromTime.hours}:${
                        fromTime.mins == 0 ? "00" : fromTime.mins
                      } to ${toTime.hours}:${toTime.mins == 0 ? "00" : toTime.mins}`,
                    };
                  }
                } else {
                  return { res: true };
                }
              } else {
                return {
                  res: false,
                  message: `Delivery can be accepted from ${fromTime.hours}:${
                    fromTime.mins == 0 ? "00" : fromTime.mins
                  } to ${toTime.hours}:${toTime.mins == 0 ? "00" : toTime.mins}`,
                };
              }
            }
          } else {
            return {
              res: false,
              message: `Delivery can be accepted from ${fromTime.hours}:${
                fromTime.mins == 0 ? "00" : fromTime.mins
              } to ${toTime.hours}:${toTime.mins == 0 ? "00" : toTime.mins}`,
            };
          }
        }
      } else {
        return {
          res: false,
          message: `We need atleast ${backingHrs} hours to bake this product! Delivery date can be on ${FormattedDate(
            new Date().setDate(new Date().getDate() + days)
          )} from ${fromTime.hours}: ${
            fromTime.mins > 0 ? fromTime.mins : "00"
          } to ${toTime.hours}: ${toTime.mins > 0 ? toTime.mins : "00"} `,
        };
      }
    } else {
      return { res: false, message: "Delivery date cannot be the past date!" };
    }
  } else {
    return { res: false };
  }
}

export function checkValidDeliveryTime(
  fDate: any,
  ftime: any,
  data: any
): { res: boolean; message?: string } {
  console.log(fDate, ftime, data, "checkValidDeliveryTime");
  setCurrentDateTime()
  /**********************Today's Variables************************************/
  /*************************Selected Variable**********************************/
  const date = new Date(fDate).getDate();
  const month = new Date(fDate).getMonth() + 1;
  const year = new Date(fDate).getFullYear();
  /************************Data from the details page***************************/
  const bakingHrs: number = checkNull(data?.backingTime)
    ? Number(data?.backingTime)
    : null;
  const deliveryFrom: hh_mm = checkNull(data?.fromTime)
    ? convertTime(data?.fromTime)
    : null;
  const deliveryTo: hh_mm = checkNull(data?.toTime)
    ? convertTime(data?.toTime)
    : null;
  const selectedTime: hh_mm = convertTime(ftime);
  const currentTime: hh_mm = convertTime(`${currentHrs}:${currentMins}`);
  const deliveryTimeName = checkNull(data?.deliveryName) ? data?.deliveryName : null;
  const cutOffTime: hh_mm = checkNull(data?.cutOffTime)
    ? convertTime(data?.cutOffTime) : null;

  /*----------------------------------This is for the any time deliverable Products------------------------------------*/

  console.log(deliveryFrom, selectedTime, "fsdhfhhsdfdh-------------->");

  if (bakingHrs == 0 || bakingHrs == null) {
    if (checkFromDateToDate(new Date(), fDate)) {
      return checkFromTimeAlone(deliveryFrom, selectedTime);
    } else {
      return { res: false, message: "Past dates cannot be accepted!" };
    }
  } else if (bakingHrs > 0 && bakingHrs <= 8) {
    //Checking the date is present or past
    if (checkValidDeliveryDate(fDate)) {
      //Check the delivery date is today or other days
      //Checking for todays date
      if (returnDateString(new Date()) == returnDateString(fDate)) {
        if (cutOffTime.hours >= currentHrs) {
          if (cutOffTime.hours == currentHrs) {
            if (cutOffTime.mins >= currentMins) {
              if (
                checkFromTimeWithBakingHrs(
                  deliveryFrom,
                  bakingHrs,
                  deliveryTo,
                  selectedTime
                ).res == true
              ) {
                //now need to check the cutt of time
                return checkTotTimeWithBakingHrs(
                  cutOffTime,
                  bakingHrs,
                  selectedTime
                );
              } else {
                return checkFromTimeWithBakingHrs(
                  deliveryFrom,
                  bakingHrs,
                  deliveryTo,
                  selectedTime
                );
              }
            } else {
              return {
                res: false,
                message: `Sorry we can't proceed for today's delivery. Reached the ordering time limit.`,
              };
            }
          } else {
            if (
              checkFromTimeWithBakingHrs(
                deliveryFrom,
                bakingHrs,
                deliveryTo,
                selectedTime
              ).res == true
            ) {
              //now need to check the cutt of time
              return checkTotTimeWithBakingHrs(cutOffTime, bakingHrs, selectedTime);
            } else {
              return checkFromTimeWithBakingHrs(
                deliveryFrom,
                bakingHrs,
                deliveryTo,
                selectedTime
              );
            }
          }
        } else {
          return {
            res: false,
            message: `Sorry we can't proceed for today's delivery.Reached the ordering time limit.`,
          };
        }
      }
      //Check for other days
      else {
        //Need to check the from time alone;
        return checkFromTimeAlone(deliveryFrom, selectedTime);
      }
    } else {
      return { res: false, message: "Delivery date can't be the past date!" };
    }
  } else if (bakingHrs >= 24) {
    // return checkFromTimeAlone(deliveryFrom, selectedTime)
    const acceptedDate = new Date(
      currentYear,
      currentMonth - 1,
      currentDate,
      bakingHrs
    );
    if (checkFromDateToDate(acceptedDate, fDate)) {
      if (returnDateString(acceptedDate) == returnDateString(fDate)) {
        return checkAlsotheDeliveryTime(currentTime, selectedTime, bakingHrs);
      } else {
        return checkFromTimeAlone(deliveryFrom, selectedTime);
      }
    } else {
      return {
        res: false,
        message: `We need atleast ${bakingHrs} hours to prepare your order. Please select a valid delivery date and time!`,
      };
    }
  } else {
    return { res: false, message: "Such delivery time is not available from us!" };
  }
}

function checkFromTimeWithBakingHrs(
  fromTime: hh_mm,
  bakingTime: number,
  toTime: hh_mm,
  selectedTime: hh_mm
): { res: boolean; message?: string } {
  if (checkCurrentTime(selectedTime)) {
    if (selectedTime.hours >= fromTime.hours) {
      if (selectedTime.hours >= currentHrs + bakingTime) {
        if (selectedTime.hours == currentHrs + bakingTime) {
          if (selectedTime.mins >= currentMins + bakingTime) {
            return { res: true }; //need to check the cutoff and totime
          } else {
            return {
              res: false,
              message: `We need atleast ${bakingTime}${
                bakingTime > 1 ? "hours" : "hour"
              } to prepare your order. You can expect delivery from ${
                currentHrs + bakingTime
              }:${returnZero(currentMins)} to ${toTime.hours}:${returnZero(
                toTime.mins
              )}`,
            };
          }
        } else {
          return { res: true }; //need to check the cutoff and totime
        }
      } else {
        return {
          res: false,
          message: `We need atleast ${bakingTime}${
            bakingTime > 1 ? "hours" : "hour"
          } to prepare your order. You can expect delivery from ${
            currentHrs + bakingTime
          }:${returnZero(currentMins)} to ${toTime.hours}:${returnZero(
            toTime.mins
          )}`,
        };
      }
    } else {
      return {
        res: false,
        message: `Delivery can be expected from ${fromTime.hours}:${returnZero(
          fromTime.mins
        )}`,
      };
    }
  } else {
    return { res: false, message: "Delivery time can't be the past time!" };
  }
}

function returnZero(value: number) {
  if (value == 0) {
    return "00";
  } else if (value < 10) {
    return `0${value}`;
  } else {
    return value;
  }
}

function checkTotTimeWithBakingHrs(
  cutOffTime: hh_mm,
  bakingTime: number,
  selectedTime: hh_mm
): { res: boolean; message?: string } {
  if (!checkNull(cutOffTime) && checkNull(selectedTime)) {
    return { res: true };
  } else {
    if (currentHrs <= cutOffTime.hours) {
      if (currentHrs == cutOffTime.hours) {
        if (currentMins <= cutOffTime.mins) {
          return { res: true };
        } else {
          return {
            res: false,
            message: `You can place order till ${cutOffTime.hours}:${returnZero(
              cutOffTime.mins
            )}`,
          };
        }
      } else {
        return { res: true };
      }
    } else {
      return {
        res: false,
        message: `You can place order till ${cutOffTime.hours}:${returnZero(
          cutOffTime.mins
        )}`,
      };
    }
  }
}

function checkFromTimeAlone(
  fromTime: hh_mm,
  selectedTime: hh_mm
): { res: boolean; message?: string } {
  console.log(fromTime, selectedTime);

  if (selectedTime.hours >= fromTime.hours) {
    if (selectedTime.hours == fromTime.hours) {
      if (selectedTime.mins >= fromTime.mins) {
        return { res: true };
      } else {
        return {
          res: false,
          message: `Delivery can be expected from ${fromTime.hours}:${returnZero(
            fromTime.mins
          )}`,
        };
      }
    } else {
      return { res: true };
    }
  } else {
    return {
      res: false,
      message: `Delivery can be expected from ${fromTime.hours}:${returnZero(
        fromTime.mins
      )}`,
    };
  }
}

export function checkAlsotheDeliveryTime(
  currentTime: hh_mm,
  selectedTime: hh_mm,
  bakingHrs
): { res: boolean; message?: string } {
  if (selectedTime.hours >= currentTime.hours) {
    if (selectedTime.hours == currentTime.hours) {
      if (selectedTime.mins >= currentTime.mins) {
        return { res: true };
      } else {
        return {
          res: false,
          message: `We need atleast ${bakingHrs} hours to prepare your order!`,
        };
      }
    } else {
      return { res: true };
    }
  } else {
    return { res: false, message: `We need atleast ${bakingHrs} hours to bake!` };
  }
}

export function checkTimeForDate(bakingHrs: number, fDate) {
  let days = Number(Math.ceil(bakingHrs / 24));
  let nextDay = new Date().setDate(new Date().getDate() + days);

  if (bakingHrs >= 24) {
    let days = Number(Math.ceil(bakingHrs / 24));
    let nextDay = new Date().setDate(new Date().getDate() + days);
    if (returnDateString(nextDay) == returnDateString(fDate)) {
      return false;
    } else {
      return true;
    }
  } else {
    if (returnDateString(today) == returnDateString(fDate)) {
      return false;
    } else {
      return true;
    }
  }
}
