import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.less']
})
export class PaginationComponent implements OnInit, OnChanges {


  @Input('currentPage') public currentPage: any = 1;

  @Input('lastPage') public lastPage: any

  @Output('paginate') public paginate: EventEmitter<any> = new EventEmitter<any>()

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);

  }


  pagination(data: any) {

    if (data == 'add' && this.currentPage != this.lastPage) {
      this.currentPage++
      console.log(this.currentPage, this.lastPage);

      this.paginate.emit(this.currentPage)
    }
    if (data == 'sub' && this.currentPage != 1) {
      this.currentPage--
      this.paginate.emit(this.currentPage)
    }
  }


  paginateByNumber(num) {

    if (this.currentPage == num) {
      return
    } else {
      this.currentPage = num;
      this.paginate.emit(this.currentPage) 
    }
  }

}
