import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LoaderService } from "src/app/services/memory-services/loader.service";

@Component({
  selector: "app-loader-component",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent1 implements OnInit {
  isLoading: Observable<any>;
  isLogo: Observable<any>;

  constructor(private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.isLoading = this.loaderService.status.asObservable();
    this.isLogo = this.loaderService.logo.asObservable();
  }
}
