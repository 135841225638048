<app-loader *ngIf="loading"></app-loader>

<button type="button" class="btn btn-primary" id="forgotPasswordModal" #forgotPasswordModal hidden="true"
    data-toggle="modal" data-target="#forgotPassword">
</button>
<div class="modal fade" id="forgotPassword" tabindex="-1" role="dialog" aria-labelledby="forgotPasswordLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body text-center">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="row">

                    <div class="col-lg-7 ptb-4">
                        <div class="row pt-4 px-3 pb-4">
                            <div class="col-lg-12 text-center">
                                <div class="row align-center pt-4 px-3 pb-4">
                                    <div class="col-lg-8">
                                        <h4>Forgot Password</h4>
                                    </div>
                                    <div class="col-lg-4 text-right">
                                        <a class="fa fa-facebook"></a>
                                        <a class="fa fa-google-plus"></a>
                                    </div>
                                </div>
                                <div class="row pt-3">
                                    <div class="col-lg-12">
                                        <div class="input-group">
                                            <input type="text" class="form-control" [(ngModel)]="resetEmail"
                                                placeholder="Email id" autocomplete="new-email">
                                        </div>
                                    </div>
                                </div>
                                <p *ngIf="errorMsg" style="color:red;">{{errorMsg}}</p>
                                <div class="row mt-4 action-btn">
                                    <div class="col-lg-12 mt-3">
                                        <button type="button" class="btn btn-custom btn-lg btn-block"
                                            (click)="submit()">Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <img src="assets/images/login/image1.png" style="height: 100%;" class="background" alt="" />
                        <div class="background-header">
                            <h3>Hello, Friend!</h3>
                            <p>Enter your personal details and start journey with us</p>
                            <button type="button" class="btn btn-custom " (click)="analyticsService.commonEvents('sign_up',{'event_category': 'Open SignIn Popup Form',
                            'event_label': 'Open SignIn Popup Form',
                            'value': 'Open SignIn Popup Form'})">Sign Up</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>