import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpService } from "../http/http.service";
import { checkNull } from "src/app/modules/product-details/date-and-time-validations";

interface COUNTS {
  wishList$?: any;
  cartItem$?: any;
}

@Injectable({
  providedIn: "root",
})
export class UserService {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);

  loggedIn$: Observable<boolean> = this.loggedIn.asObservable();

  // Current User details
  private currentUserSubject: BehaviorSubject<any | null> = new BehaviorSubject("");

  currentUser$: Observable<any | null> = this.currentUserSubject.asObservable();

  // WishList count

  private wishListCount: BehaviorSubject<any> = new BehaviorSubject(0);

  wishList$: Observable<any> = this.wishListCount.asObservable();

  // CartItem Count

  private cartItemCount: BehaviorSubject<any> = new BehaviorSubject(0);

  cartItem$: Observable<any> = this.cartItemCount.asObservable();

  // get the local and pan Items
  private hasPanProduct$: BehaviorSubject<any | null> = new BehaviorSubject(null);

  public hasPanProduct: Observable<any> = this.hasPanProduct$.asObservable();

  // door or store
  private deliveryType$: BehaviorSubject<any | null> = new BehaviorSubject(null);

  public deliveryType: Observable<any> = this.deliveryType$.asObservable();

  // pincodes
  private pincode$: BehaviorSubject<any | null> = new BehaviorSubject(null);

  public pincode: Observable<any> = this.pincode$.asObservable();

  constructor(private service: HttpService) {}

  setCurrentUser() {
    const currentUser = localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser"))
      : null;
    this.setCartItem();
    this.setWishlist();
    this.setloggedIn();
    this.currentUserSubject.next(currentUser);
  }

  getAllPincodes() {
    this.service.post("/user/delivery", { value: "" }).subscribe(
      (res: any) => {
        this.pincode$.next(res);
      },
      (err) => {
        this.pincode$.next([]);
      }
    );
  }

  private setloggedIn() {
    const currentUser = localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser"))
      : null;
    if (checkNull(currentUser)) {
      this.loggedIn.next(true);
    } else {
      this.loggedIn.next(false);
    }
  }

  setWishlist() {
    const currentUser = localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser"))
      : null;

    if (checkNull(currentUser)) {
      this.service.get("/user/wishlist").subscribe(
        (data) => {
          const result: any = data;
          this.wishListCount.next(result.content.length);
        },
        (err) => {
          this.wishListCount.next(0);
        }
      );
    } else {
      this.wishListCount.next(0);
    }
  }

  setCartItem() {
    const currentUser = localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser"))
      : null;
    if (checkNull(currentUser)) {
      this.service.get("/order").subscribe(
        (data) => {
          const result: any = data;
          console.log(data);

          localStorage.setItem(
            "orderId",
            result?.orderId != null ? result?.orderId : "-"
          );
          let customCartData = [];
          result.data.lineItemsInfo.map((x) => {
            if (x.attribute.cartType == "main") {
              customCartData.push(x);
            }
          });
          this.cartItemCount.next(customCartData.length ?? 0);
        },
        (error) => {
          localStorage.removeItem("orderId");
          this.cartItemCount.next(0);
        }
      );
    } else {
      this.cartItemCount.next(0);
    }
  }

  setPanCounts(): Observable<
    "zeroLength" | "hasnoPan" | "hasPan" | "hasboth" | "error"
  > {
    return new Observable((observer) => {
      this.service.get("/order").subscribe(
        (data: any) => {
          const result: any = data;
          // let time: any[] =
          //   result?.data?.lineItemsInfo
          //     .map((ele) => ele.attribute.deliveryIn)
          //     .every((ele) => checkNull(ele)) ||
          //   result?.data?.lineItemsInfo?.length == 0;

          const zeroLength = result?.data?.lineItemsInfo?.length == 0;
          const hasnoPan = result?.data?.lineItemsInfo
            .map((ele) => ele.attribute.deliveryIn)
            .every((ele) => checkNull(ele));
          const hasPan = result?.data?.lineItemsInfo
            .map((ele) => ele.attribute.deliveryIn)
            .every((ele) => !checkNull(ele));
          switch (true) {
            case zeroLength:
              observer.next("zeroLength");
              break;
            case hasnoPan:
              observer.next("hasnoPan");
              break;
            case hasPan:
              observer.next("hasPan");
              break;
            default:
              observer.next("hasboth");
          }
        },
        (error) => {
          this.deliveryType$.next("error");
          observer.next("error");
        }
      );
    });
  }

  setDeliveryType(): Observable<"noOrders" | "hasDoor" | "hasStore" | "error"> {
    return new Observable((observer) => {
      this.service.get("/order").subscribe(
        (data: any) => {
          const result: any = data;

          const hasNoOrders = result?.data?.lineItemsInfo?.length == 0;

          const hasDoor = result?.data?.lineItemsInfo.some(
            (ele) => ele.attribute.door == true
          );

          const hasStore = result?.data?.lineItemsInfo.some(
            (ele) => ele.attribute.store == true
          );

          switch (true) {
            case hasNoOrders:
              observer.next("noOrders");
              break;
            case hasDoor:
              observer.next("hasDoor");
              break;
            case hasStore:
              observer.next("hasStore");
              break;
            default:
              observer.next("error");
              break;
          }
        },
        (error) => {
          observer.next("error");
        }
      );
    });
  }
}
