<div class="loadersection" *ngIf="isLogo|async">
  <div class="loader">
    <div class="spinner-border" style="width: 6rem; height: 6rem;color:#fc98b2" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>

<div class="preloader" *ngIf="isLoading|async">
  <div class="lds-dual-ring"></div>
</div>