<app-loader *ngIf="loading"></app-loader>
<section class="bg-color container-fluid">
  <!--<div class="top-rounded-border"></div>-->
  <!--add bootstrap slider-->
  <!-- <app-slider [type]="formType"></app-slider>
    <div class="container-fluid px-5">
        <ol class="breadcrumb px-0">
            <li class="breadcrumb-item">
                <a href="#"><i _ngcontent-pcj-c17="" class="fa fa-home"> Home</i></a>
            </li>
            <li class="breadcrumb-item active">{{subtitle}}</li>
        </ol>
    </div> -->
  <section class="enquiryform">
    <div class="row" style="align-items: center">
      <div class="col-lg-5 left-side">
        <h3><b>Let's get talking</b></h3>
        <p>
          We'd love to talk to you about how we can help.<br />Please leave your
          details here and one of our team<br />will get back to you as soon as
          possible
        </p>
      </div>
      <div class="col-lg-7">
        <div class="contact-wrap" id="enquiryFormContainer">
          <h4 class="title">{{ title }}</h4>
          <form [formGroup]="form" class="contact-form row">
            <mat-tab-group style="width: 100%" [(selectedIndex)]="selectedTabIndex"
              (selectedTabChange)="tabChanged($event?.index)">
              <mat-tab label="Delivery Details" class="delivery-details" id="myModal">
                <div class="row" style="margin-right: 0px; margin-left: 0px; padding: 10px">
                  <div class="col-lg-6">
                    <label for="">Name <span style="color: red;">*</span></label>
                    <div class="mb-15">
                      <input class="form-control" type="text" placeholder="Name" formControlName="name"
                        autocomplete="new-name" />
                    </div>
                    <div *ngIf="submitted && f.name.errors" class="invalid-text">
                      <div *ngIf="f.name.errors.required">Name is required</div>
                      <div *ngIf="f.name.errors.pattern">
                        Enter Valid Name
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <label for="">Mobile Number <span style="color: red;">*</span></label>
                    <div class="mb-15">
                      <input class="form-control" type="text" placeholder="Mobile number" formControlName="phoneNumber"
                        autocomplete="new-phoneNumber" />
                    </div>
                    <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-text">
                      <div *ngIf="f.phoneNumber.errors.required">
                        Mobile Number is required
                      </div>
                      <div *ngIf="f.phoneNumber.errors.pattern">
                        Enter Valid 10 Digit Mobile Number
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <label for="">Email</label>
                    <div class="mb-15">
                      <input class="form-control" type="text" placeholder="Email" formControlName="email"
                        autocomplete="new-email" />
                    </div>
                    <div *ngIf="submitted && f.email.errors" class="invalid-text">
                      <div *ngIf="f.email.errors.email">Enter Valid Email</div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <label for="">Delivery Date Time</label>
                    <div class="mb-15">
                      <input id="datetimepicker" class="form-control" type="text" placeholder="Delivery date time"
                        formControlName="deliveryDateTime" readonly autocomplete="new-datetimepicker" />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <label for="">Address</label>
                    <div class="mb-15 textarea">
                      <textarea rows="3" type="text" placeholder="Type your text" class="form-control"
                        formControlName="address" autocomplete="new-address"></textarea>
                    </div>
                  </div>
                  <div class="col-lg-6" *ngIf="formType != 1">
                    <label for="">Is Your Place Air Conditioned</label>
                    <div class="mb-15">
                      <select class="form-control" formControlName="airConditioned">
                        <option value="" selected disabled>Select <a href="tel:+"></a>air conditioned</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-6" *ngIf="formType != 1">
                    <label for="">Contact At Venue</label>
                    <div class="mb-15">
                      <input class="form-control" type="text" placeholder="Contact at venue"
                        formControlName="contactAtVenue" autocomplete="new-contactAtVenue" />
                    </div>
                  </div>

                  <div class="col-lg-12 list-function">
                    <input class="mt-1" name="deliveryType" formControlName="deliveryType" type="radio"
                      value="Door Delivery" autocomplete="new-deliveryType" />
                    <label for="" class="ml-2"> Door Delivery</label>

                    <input class="mt-3" name="deliveryType" placeholder="Door delivery" formControlName="deliveryType"
                      type="radio" value="In Store Pickup" style="margin-left: 30px" autocomplete="new-deliveryType" />
                    <label for="" class="ml-2 ml-5p"> In Store Pickup</label>


                  </div>

                  <div class="col-lg-12">
                    <button class="submit-btn" (click)="tabChanged(1)">
                      Next
                    </button>
                  </div>
                </div>
              </mat-tab>

              <mat-tab label="Cake Preferences" [disabled]="
                  form.get('email').invalid || form.get('name').invalid
                ">
                <div class="row" style="margin-right: 0px; margin-left: 0px; padding: 10px">
                  <div class="col-lg-6">
                    <label for="">Cream Type</label>
                    <div class="mb-15">
                      <select class="form-control" formControlName="creamType">
                        <option value="" selected disabled>Select cream type</option>
                        <option value="Fresh Cream">Fresh Cream</option>
                        <option value="Butter Cream">Butter Cream</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label for="">Cake Flavour</label>
                    <div class="mb-15">
                      <input class="form-control" type="text" placeholder="Cake flavour" formControlName="flavour"
                        autocomplete="new-flavour" />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label for="">Cake ICING</label>
                    <div class="mb-15">
                      <select class="form-control" formControlName="cakeIcing">
                        <option value="" selected disabled>Select cake icing</option>
                        <option value="Cream Finishing">Cream Finishing</option>
                        <option value="Fondant Covering">
                          Fondant Covering
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-6" *ngIf="formType != 2">
                    <label for="">Cake Weight</label>
                    <div class="mb-15">
                      <input class="form-control" placeholder="Cake weight" type="text" formControlName="cakeWeight"
                        autocomplete="new-cakeWeight" />
                    </div>
                  </div>

                  <div class="col-lg-6" *ngIf="formType != 2">
                    <label for="">Number of TIERS</label>
                    <div class="mb-15">
                      <input class="form-control" type="text" placeholder="Number of time" formControlName="cakeTiers"
                        autocomplete="new-cakeTiers" />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label for="">Cake Theme</label>
                    <div class="mb-15">
                      <input class="form-control" type="text" placeholder="Cake theme" formControlName="cakeTheme"
                        autocomplete="new-cakeTheme" />
                    </div>
                  </div>

                  <div class="col-lg-6" *ngIf="formType != 2">
                    <label for="">Cake Shape</label>
                    <div class="mb-15">
                      <select class="form-control" formControlName="cakeShape">
                        <option value="" selected disabled>Select cake shape</option>
                        <option value="Round Shape">Round Shape</option>
                        <option value="Heart Shape">Heart Shape</option>
                        <option value="Square Shape">Square Shape</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <label for="">Extra Information</label>
                    <div class="mb-15">
                      <input class="form-control" type="text" placeholder="Extra information"
                        formControlName="extraInformation" autocomplete="new-extraInformation" />
                    </div>
                  </div>

                  <div class="col-lg-6" *ngIf="formType == 2">
                    <label for="">Number of Pax (Guests)</label>
                    <div class="mb-15">
                      <input class="form-control" type="text" placeholder="Number of pax (Guests)"
                        formControlName="noOfGuest" autocomplete="new-noOfGuest" />
                    </div>
                  </div>

                  <div class="col-lg-12" *ngIf="formType == 2">
                    <label for="">Need Back Drop & Table Decorations ?</label>
                    <div class="mb-15">
                      <select class="form-control" formControlName="backDrop">
                        <option value="" selected disabled>Select back drop & table decorations</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-6" *ngIf="formType == 2">
                    <label for="">Special Instruction</label>
                    <div class="mb-15">
                      <input class="form-control" type="text" placeholder="Special instruction"
                        formControlName="specialInstructions" autocomplete="new-specialInstructions" />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <button class="submit-btn" (click)="tabChanged(2)">
                      Next
                    </button>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Image Reference" [disabled]="selectedTabIndex != 2">
                <div class="row" style="margin-right: 0px; margin-left: 0px; padding: 10px">
                  <div class="col-lg-12  col-md-12" style="margin-bottom: 10px" *ngIf="formType == 2">
                    <div class="row" style="margin-top: 10px">
                      <div class="col-lg-6 col-md-6 " *ngFor="
                          let item of DessertPreferences;
                          let index = index
                        ">
                        <input (click)="changeCheckbox(item, $event)" type="checkbox" class="checkbox-des" />
                        <label class="ch-label">{{ item }}</label>
                        <br *ngIf="index == 9" />
                      </div>
                    </div>
                  </div>
                  <!--imager upload section-->
                  <div class="col-lg-12">
                    <b>Reference Image</b> <br />
                    <div style="margin-top: 10px">
                      <input id="upload-degn" type="file" (change)="uploadFile($event)" accept="image/png, image/jpeg"
                        style="display: none" />
                      <label for="upload-degn" *ngIf="!imgUrl">
                        <span class="btn btn-file-upload">
                          <!--Choose Image-->Upload
                          <i class="fa fa-upload" aria-hidden="true"></i>
                        </span>
                      </label>
                      <div class="mt-2" *ngIf="imgUrl">
                        <span style="color: #fe9ab3; font-weight: 600">
                          Image Uploaded
                          <i class="fa fa-check" style="
                              color: #39e600;
                              font-size: 18px;
                              margin-right: 10px;
                            "></i>
                          <i class="fa fa-trash del-icon" style="color: red; cursor: pointer"
                            (click)="deleteImage()"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <!--End imager upload section-->
              </mat-tab>
            </mat-tab-group>
            <div class="col-lg-12" *ngIf="selectedTabIndex == 2">
              <button class="submit-btn" (click)="onSubmit()">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="whatsapp-icon" style="background-color: #22ce5a">
      <a href="https://api.whatsapp.com/send?phone=+91 87540 21528 &amp;text= I'm interested in this product and I have a few questions. Can you help?"
        target="_blank">
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
          <path
            d="M10.8039 0.0681698C6.25549 0.756887 2.54823 3.69142 0.901889 7.90756C-0.353895 11.1236 -0.185656 14.8007 1.35253 17.9149L1.71304 18.6396L0.841803 21.8077C0.367129 23.5504 -0.0174167 24.9877 0.00060889 24.9997C0.012626 25.0117 1.48472 24.6404 3.27526 24.1733L6.53188 23.3228L7.19282 23.6343C10.6297 25.2393 14.5833 25.2213 17.9661 23.5804C19.5464 22.8138 20.9704 21.6939 22.13 20.3105C24.1609 17.891 25.2064 14.639 24.9661 11.5069C24.6236 7.04517 22.0039 3.1644 18.0262 1.23C16.8966 0.679031 15.9533 0.3736 14.6314 0.12207C13.9164 -0.00968513 11.555 -0.045618 10.8039 0.0681698ZM14.343 2.18822C15.3164 2.35591 16.2537 2.67332 17.2151 3.15841C18.2906 3.7034 19.0176 4.23041 19.8588 5.06885C21.7875 6.99127 22.797 9.31494 22.9051 12.0459C22.9893 14.3156 22.3463 16.4656 21.0004 18.382C20.4236 19.2085 19.2039 20.3943 18.3567 20.9513C16.5842 22.1311 14.9138 22.6641 12.8288 22.718C10.7018 22.7779 9.13358 22.3766 7.08467 21.2327L6.83832 21.095L4.93962 21.5921C3.90015 21.8676 3.01689 22.0832 2.98685 22.0712C2.95681 22.0592 3.1611 21.2267 3.43749 20.2206C3.70787 19.2145 3.9362 18.3581 3.9362 18.3102C3.9362 18.2623 3.83405 18.0646 3.70186 17.861C2.06754 15.2798 1.68901 11.9321 2.70445 9.02149C3.7259 6.11091 5.95506 3.81718 8.86319 2.69727C9.68636 2.37986 10.5215 2.19421 11.7473 2.05048C12.1979 1.99658 13.676 2.07443 14.343 2.18822Z"
            fill="white" />
          <path
            d="M7.81182 6.69762C7.3732 6.8174 6.70626 7.61392 6.39381 8.39247C6.26763 8.70987 6.25562 8.84762 6.25562 9.59023C6.25562 10.4107 6.26162 10.4407 6.47192 11.0216C7.0908 12.6745 8.96546 14.9742 10.8221 16.3636C11.8135 17.1062 12.6908 17.5434 14.1929 18.0465C15.5088 18.4837 16.374 18.4717 17.3353 17.9986C18.0263 17.6572 18.525 17.1601 18.6813 16.6571C18.8315 16.1839 18.8795 15.4653 18.7714 15.3335C18.6031 15.1299 16.0435 13.9321 15.7791 13.9321C15.5748 13.9321 15.5869 13.9202 14.986 14.6748C14.4272 15.3695 14.247 15.5491 14.0847 15.5491C13.8444 15.5491 12.7929 15.0341 12.162 14.6209C11.8435 14.4053 11.3569 14.022 11.0865 13.7704C10.2933 13.0278 9.40409 11.8121 9.40409 11.4707C9.40409 11.3689 9.52426 11.1833 9.77061 10.9018C10.1552 10.4646 10.4255 10.0095 10.4255 9.79984C10.4255 9.68007 9.57232 7.55403 9.344 7.10487C9.28391 6.99108 9.18778 6.85333 9.12769 6.79943C8.98349 6.67367 8.14229 6.60779 7.81182 6.69762Z"
            fill="white" />
        </svg>
      </a>
    </div>
  </section>
</section>
<section class="gallery">
  <div class="px-110">
    <h3><b>Our Speciality</b></h3>
    <h4><b>Have a look</b></h4>

    <div class="row row-1" infiniteScroll [infiniteScrollDistance]="0.1" (scrolled)="onScroll()"
      [alwaysCallback]="true">
      <div class="col-lg-3 mt-4" *ngFor="let data of Gallery; let i = index">
        <div class="card mb-3 box-shadow" id="lightbox">
          <a>
            <!-- <img class="card-img-top" src="{{imgPth+gallery.image}}" /> -->
            <img (click)="gallerypopup(data.image)" class="pro-img" [lightbox]="0" [gallery]="'anotherLightbox'"
              src="{{ data?.image }}" alt="gallery" />
          </a>
        </div>
      </div>
      <!--Product Loop End-->
    </div>
    <!-- loader gif -->
    <div class="text-center" *ngIf="loader">
      <img src="/assets/images/gif-infinite-scroll3.gif" style="height: 100px; width: auto" />
    </div>
  </div>
</section>

<!--add tesimonial-->
<div *ngIf="formType == 2">
  <app-desert-testimonial [type]="'enquiry'"></app-desert-testimonial>
</div>
<div *ngIf="formType == 3">
  <app-wedding-testimonial [type]="'enquiry'"></app-wedding-testimonial>
</div>
<div *ngIf="formType == 1">
  <app-custom-testimonial [type]="'enquiry'"></app-custom-testimonial>
</div>
<!-- <app-testimonial  [type]="'enquiry'"></app-testimonial> -->
<section class="cake__footer__section">
  <div class="container">
    <div class="row" style="align-items: center">
      <div class="col-md-8 col-sm-7 col-xs-12">
        <div class="cake__footer__content">
          <h4>Turn your moments to memories with cake made just for you</h4>
          <button class="btn order__btn" (click)="goUpEnquiry()">
            order now
          </button>
        </div>
      </div>
      <div class="col-md-4 col-sm-5 col-xs-12">
        <div>
          <img src="/assets/images/cake-footer.png" />
        </div>
      </div>
    </div>
  </div>
</section>