<section class="pt-4" id="blog">
   <!-- top circle border -->
   <div class="top-rounded-border"></div>

   <div class="container-fluid px-5">
      <ol class="breadcrumb px-0">
         <li class="breadcrumb-item">
            <a href="#"><i _ngcontent-pcj-c17="" class="fa fa-home"> Home</i></a>
         </li>
         <li class="breadcrumb-item active">Blogs</li>
      </ol>
   </div>
   <!--  skeleton loader -->

   <div class="container-fluid px-5 pt-2 ps-4 pe-4 pb-4">
      <h3 class="pb-3">Blogs</h3>

      <div class="" *ngIf="blogs?.length==0&&(skeletonLoader|async)==false" style="    text-align: center;
      font-weight: 900;
      font-size: large;
      padding: 65px;">
         NO BLOGS FOUND
      </div>

      <div *ngIf="(skeletonLoader|async)==true" class="container-fluid px-5 py-4">
         <div class="row mt-4">
            <div class="col-md-6" *ngFor="let option of [1,2,3,4]">
               <div class="media">
                  <ngx-skeleton-loader
                     [theme]="{ height: '180px', width: '180px', 'margin-top': '15px', 'margin-left': '15px' }"
                     count="1" appearance="line">
                  </ngx-skeleton-loader>
                  <div class="media-body">
                     <ngx-skeleton-loader [theme]="{ height: '40px', width: '100%', 'margin-bottom': '0' }" count="1"
                        appearance="line">
                     </ngx-skeleton-loader>
                     <ngx-skeleton-loader
                        [theme]="{ height: '20px', width: '100%', 'margin-top': '15px','margin-bottom': '0px' }"
                        count="2" appearance="line">
                     </ngx-skeleton-loader>
                     <h4 style="margin-bottom: 0px;margin-top: 20px;">
                        <ngx-skeleton-loader [theme]="{ height: '20px', width: '40%', 'margin-top': '10px' }" count="1"
                           appearance="line">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader
                           [theme]="{ height: '20px', width: '40%', 'margin-top': '10px','margin-left': '93px' }"
                           count="1" appearance="line">
                        </ngx-skeleton-loader>
                     </h4>
                  </div>
               </div>
            </div>
         </div>
      </div>


      <div class="row mt-4" *ngIf="blogs?.length!=0&&(skeletonLoader|async)==false">
         <div class="col-md-6" *ngFor="let data of blogs">
            <div class="media" (click)="navigate(data?.id,data.title.rendered)">
               <img class="" [src]="checkNull(data._embedded['wp:featuredmedia']) ?
               data._embedded['wp:featuredmedia'][0].media_details?.sizes?.woocommerce_thumbnail.source_url: 
               'assets/images/blog-page-placeholder-image.jpg'" alt={{data?.title?.rendered}}>
               <div class="media-body">
                  <h5 class="mt-0">{{data?.title?.rendered}}</h5>


                  <!-- Hidden Content -->
                  <div class="content text-justify" [innerHTML]="data?.content?.rendered" [id]="'rendered'+data?.id"
                     style="display: none;">
                  </div>
                  <!-- Visible Content -->
                  <div class="mt-15 content text-justify" [id]="'nonrendered'+data?.id"
                     [innerHTML]="getTextContent(data?.id)"></div>


                  <div class="d-flex mt-4 justify-content-between align-items-center">

                     <h4 class="mb-0">
                        <small>Created on
                           {{ data.date|date:'dd-MM-yyyy hh:mm a' }}</small>
                     </h4>
                     <button type="button" class="continue-btn">Read More<i class="fa rightArrow ml-2 fa-angle-right"
                           aria-hidden="true"></i></button>
                  </div>
               </div>
            </div>
         </div>
      </div>


      <div class="pagination-align py-4" *ngIf="blogs?.length!=0&&(skeletonLoader|async)==false">
         <app-pagination [currentPage]="pagination.page" [lastPage]="pagination.lastPage"
            (paginate)="paginate($event)"></app-pagination>
      </div>
   </div>
</section>