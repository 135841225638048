import { Component, OnInit } from '@angular/core';
import { BlogDTO, BlogService } from '../blog-service/blog-service';
import { LoaderService } from 'src/app/services/memory-services/loader.service';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { checkNull } from '../../product-details/date-and-time-validations';

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.less']
})
export class BlogListComponent implements OnInit {
  Bloglists: any = sampleblogList

  blogs: any[] = []
  sampleBlogs: any[] = []
  pagination: BlogDTO
  public skeletonLoader: BehaviorSubject<any> = new BehaviorSubject(false)
  constructor(
    private service: BlogService,
    private loader: LoaderService,
    private router: Router

  ) {
    this.pagination = new BlogDTO()
  }

  ngOnInit(): void {
    this.getAllBlogs()
    this.getAllPostCounts()

    // Test

    // this.getSampleBlogs()
  }


  checkNull(data) {
    return checkNull(data)
  }



  getAllPostCounts() {
    this.service.getPostCounts().subscribe((res: any) => {
      console.log(res.headers.get('X-WP-Total'));
      console.log(res.headers.get('X-WP-TotalPages'));
      this.pagination.lastPage = Math.ceil(Number(res.headers.get('X-WP-Total') ?? 1) / this.pagination.per_page)
    })
  }
  getAllBlogs() {
    this.skeletonLoader.next(true)
    this.service.getAllBlogs(this.pagination).subscribe((res: any) => {
      console.log(res);
      this.blogs = res ?? []
      this.skeletonLoader.next(false)
    }, (err: any) => {
      this.skeletonLoader.next(false)
      this.blogs = [];
      console.log(err);
    })
  }

  paginate(event) {
    this.pagination.page = event;
    this.getAllBlogs()
  }

  getSampleBlogs() {
    this.loader.open()
    this.service.getSampleBlogs().subscribe((res: any) => {
      this.loader.close()

      this.sampleBlogs = res?.default ?? []
    }, (err: any) => {
      this.loader.close()
      this.sampleBlogs = [];
      console.log(err);

    })
  }


  navigate(id, title) {
    this.router.navigate([`/blogs/${id}`], { queryParams: { title: title } })
  }


  getTextContent(id) {
    let text = document.getElementById(`rendered${id}`).textContent;
    document.getElementById(`nonrendered${id}`).innerHTML = text;
  }

}


let sampleblogList = [
  { image: 'https://otwthemes.com/demos/blog-manager/wp-content/uploads/otwbm/tmb/food-13_1434541906_214X214_c_c_0_FFFFFF.jpg', title: 'Slider Media Type 1', content: 'Nam libero leo, elementum in, dapibus a, suscipit vitae, purus. Duis arcu. xcepteur sint occaecat cupidatat non proident, sunt in', createdOn: '26-1-2024', author: 'Puppys Admin' },
  { image: 'https://otwthemes.com/demos/blog-manager/wp-content/uploads/otwbm/tmb/food-3_1434541906_214X214_c_c_0_FFFFFF.jpg', title: 'Slider Media Type 2', content: 'Nam libero leo, elementum in, dapibus a, suscipit vitae, purus. Duis arcu. xcepteur sint occaecat cupidatat non proident, sunt in', createdOn: '27-1-2024', author: 'Puppys Admin' },
  { image: 'https://otwthemes.com/demos/blog-manager/wp-content/uploads/otwbm/tmb/food-13_1434541906_214X214_c_c_0_FFFFFF.jpg', title: 'Slider Media Type 1', content: 'Nam libero leo, elementum in, dapibus a, suscipit vitae, purus. Duis arcu. xcepteur sint occaecat cupidatat non proident, sunt in', createdOn: '26-1-2024', author: 'Puppys Admin' },
  { image: 'https://otwthemes.com/demos/blog-manager/wp-content/uploads/otwbm/tmb/food-3_1434541906_214X214_c_c_0_FFFFFF.jpg', title: 'Slider Media Type 2', content: 'Nam libero leo, elementum in, dapibus a, suscipit vitae, purus. Duis arcu. xcepteur sint occaecat cupidatat non proident, sunt in', createdOn: '27-1-2024', author: 'Puppys Admin' },
]
