import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConstants, WordpressConfig } from "src/app/configuration/app.constants";
import { checkNull } from "../../product-details/date-and-time-validations";
import * as post from './post.json'
import { of } from "rxjs";

export class BlogDTO {
    page = 1
    per_page = 4
    search

    /**
     * @Order sort attribute ascending or descending.
       Default: desc
       One of: asc, desc
     */
    order

    /**
     * @Sort collection by post attribute.
       Default: date
       One of: author, date, id, include, modified, parent, relevance, slug, include_slugs, title
     */
    orderby;
    lastPage
}
@Injectable({
    providedIn: 'root'
})

export class BlogService {

    constructor(private http: HttpClient) {

    }



    getAllBlogs(pagination) {
        let params = new HttpParams();

        for (let data in pagination) {
            if (checkNull(pagination[data])) {
                params = params.append(data, pagination[data])
            }
        }
        return this.http.get(`${WordpressConfig.baseURl}?_embed`, { params: params })
    }

    getPostCounts() {
        return this.http.get(`${WordpressConfig.baseURl}`, { observe: 'response' })
    }



    getSingleBlog(id) {
        return this.http.post(`${WordpressConfig.baseURl}/${id}?_embed`, '')
    }


    getSampleBlogs() {
        return of(post)
    }


    getSingleSampleBlog(id) {
        return of(post)
    }
}