import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "trimtext",
})
export class TrimText implements PipeTransform {
  transform(text: string, number?: any, str?: string) {
    if (text.length <= number) {
      return text;
    } else {
      return text.slice(0, number ?? 5) + `${str ?? "."}${str ?? "."}${str ?? "."}`;
    }
  }
}
