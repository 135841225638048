<app-loader *ngIf="loading"></app-loader>
<section id="checkout" class="pt-4">
   <!-- top circle border -->
   <div class="top-rounded-border"></div>
   <!--main row and container start-->
   <div class="container-fluid custom-fluid vh">
      <div *ngIf="customCartData">
         <div class="card" *ngIf="cartItemCount == 0">
            <div class="empty-cart">
               <h2>My Cart</h2>
               <div class="text-center">
                  <img src="assets/images/empty-cart.png" width="28%" />
                  <h4 class="mt-5 mb-0">Your Cart is Empty</h4>
                  <h6>Add items to it now</h6>
                  <button [routerLink]="''" type="button" class="btn btn-custom">
                     Shop Now
                  </button>
               </div>
            </div>
         </div>
      </div>
      <div class="row">
         <!---------------Start Left------------------------->
         <div class="col-lg-12 col-xl-8">
            <div *ngIf="customCartData">
               <div class="order-details" *ngIf="cartItemCount > 0">
                  <div class="card p-15 mb-15">
                     <div class="row">
                        <div class="col-lg-6">
                           <h4>Order Details</h4>
                        </div>
                     </div>
                  </div>
                  <div class="card p-15 mb-15" *ngIf="ShowHomeAndStore('home')">
                     <div class="row">
                        <div class="col-lg-6">
                           <div class="sub-address">
                              <h5>Home Address</h5>
                              <ul *ngIf="getCartData2?.homeAddress?.id != undefined">
                                 <li>Name : {{ getCartData2?.homeAddress?.name }}</li>
                                 <li *ngIf="getCartData2?.homeAddress?.email != ''">
                                    Email : {{ getCartData2?.homeAddress?.email }}
                                 </li>
                                 <li>
                                    Mobile : {{ getCartData2?.homeAddress?.phoneNumber }}
                                    {{
                                    getCartData2?.homeAddress?.alternativePhoneNumber
                                    ? ","
                                    : ""
                                    }}
                                    {{ getCartData2?.homeAddress?.alternativePhoneNumber }}
                                 </li>
                                 <li>
                                    {{ getCartData2?.homeAddress?.line1
                                    }}{{ getCartData2?.homeAddress?.city ? "," : "" }}
                                    {{ getCartData2?.homeAddress?.city
                                    }}{{ getCartData2?.homeAddress?.pinCode ? "," : "" }}
                                    {{ getCartData2?.homeAddress?.pinCode }}
                                 </li>
                              </ul>
                              <p class="text-center" *ngIf="getCartData2?.homeAddress?.id == undefined">
                                 Home Address Not Found..!
                              </p>
                           </div>
                        </div>
                        <div class="col-lg-6 text-right">
                           <div>
                              <button class="btn add-adr-head" *ngIf="getCartData2?.homeAddress?.id == undefined"
                                 (click)="openAddAddressModal('door')">
                                 Add Delivery Address
                              </button>
                              <button class="btn add-adr-head" *ngIf="getCartData2?.homeAddress?.id != undefined"
                                 (click)="openAddAddressModal('door')">
                                 Change Delivery Address
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <!-----add to cart => product loop start------->
                  <ng-container *ngFor="let item of customCartData; let index = index">
                     <div class="card p-15 mb-15" *ngIf="item.attribute.door == true">
                        <!--<h6 class="ord-detl-h6">Order {{index+1}} </h6>-->
                        <div [ngClass]="(validData[index]==false||panProducts[index].status==false) ? 'blurclass' : ''">
                           <!--row start-->
                           <div class="card p-15 mb-15">
                              <div class="row">
                                 <div class="col-lg-12">
                                    <div class="row">
                                       <div class="col-lg-2 col-md-2 col-sm-2">
                                          <div [ngClass]="!panProducts[index]?.isPanProduct ? 'd-none':''"
                                             class="ribbon ribbon-top-left"><span>PAN INDIA</span></div>
                                          <a routerLink="/details/{{ item.product.productId }}">
                                             <img *ngIf="!item.product?.productImages.includes('mp4')"
                                                src="{{ imgPth + item.product?.productImages }}" alt="" srcset="" />
                                             <video *ngIf="item.product?.productImages.includes('mp4')"
                                                [src]="imgPth + item.product?.productImages"> </video>
                                          </a>
                                       </div>
                                       <div class="col-lg-6 col-md-6 col-sm-6 pl-0">
                                          <h4>{{ item.product.productName }}</h4>
                                          <div *ngIf="!panProducts[index]?.isPanProduct">
                                             <img src="./assets/images/delivery.png" style="
                                                width: 6%;
                                                margin-right: 5px;
                                                margin-top: -5px;
                                                " />
                                             <span class="delon1">Estimated Delivery : </span>
                                             <span class="delon2">{{
                                                item.attribute.deliveryIn
                                                }}</span>
                                          </div>
                                          <p class="classes">
                                             {{ item.pricing.kg }}
                                             <span *ngIf="item.product.isKg">Kg</span>
                                             <span *ngIf="!item.product.isKg">Nos</span>
                                             <small class="verticalLine"></small>
                                             {{ item.pricing.flavour ? item.pricing.flavour : 'NA' }}
                                             <small class="verticalLine" *ngIf="item.attribute.eggType"></small>
                                             {{ item.attribute.eggType ? (item.attribute.eggType | titlecase) : '' }}
                                             <small class="verticalLine"></small>
                                             Quantity
                                             <select #val (change)="changeQuantity(val.value, item)"
                                                value="{{ item.quantity }}" style="
                                             border-color: #fe9ab3;
                                             border-radius: 5px;
                                             padding: 2px;
                                             margin-left: 5px;
                                             ">
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                             </select>
                                          </p>
                                          <p *ngIf="item?.attribute?.message">
                                             Message On Cake : {{ item?.attribute?.message }}
                                          </p>
                                       </div>
                                       <div class="col-lg-1 col-md-1 col-sm-1"></div>
                                       <div class="col-lg-3 col-md-3 col-sm-3">
                                          <div class="rupee-part">
                                             <!-- *ngIf="!panProducts[index]?.isPanProduct" -->
                                             <small class="rupee">₹ {{ item.cost.toFixed(2) }}</small>
                                             <a *ngIf="!checkDeliveryTime(item)" (click)="changeQuantity(0, item)"
                                                [ngClass]="panProducts[index]?.isPanProduct ? 'd-none':''" class="del-a"
                                                style="cursor: pointer"><i class="fa fa-trash"
                                                   aria-hidden="true"></i>Remove</a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <!--row end-->
                           <div class="row">
                              <div class="col-lg-12">
                                 <div class="row" *ngIf="item.subItem">
                                    <!----------gift category loop start------------>
                                    <div class="col-lg-12 col-sm-12 col-12" *ngFor="let data of item.subItem">
                                       <div class="card p-15 mb-15">
                                          <div class="row">
                                             <div class="col-lg-12">
                                                <div class="row">
                                                   <div class="col-lg-2 col-md-2 col-sm-2">
                                                      <div class="sub-image-part">
                                                         <a routerLink="/details/{{
                                                            data.product.productId
                                                            }}">
                                                            <img class="sub-img" src="{{
                                                            imgPth + data.product?.productImages
                                                            }}" alt="" srcset="" />
                                                         </a>
                                                      </div>
                                                   </div>
                                                   <div class="col-lg-6 col-md-6 col-sm-6 pl-0">
                                                      <div class="d-flex">
                                                         <h4>{{ data.product.productName }}</h4>
                                                         <span class="addon-label">Addon</span>
                                                      </div>
                                                      <p class="classes">
                                                         {{ data.pricing.kg }}
                                                         <span *ngIf="data.product.isKg">Kg</span>
                                                         <span *ngIf="!data.product.isKg">Nos</span>
                                                         <small class="verticalLine"></small>
                                                         {{ data.pricing.flavour ? data.pricing.flavour : 'NA' }}
                                                         <small class="verticalLine"
                                                            *ngIf="data.attribute.eggType"></small>
                                                         {{ data.attribute.eggType ? (data.attribute.eggType |
                                                         titlecase) : '' }}
                                                         <small class="verticalLine"></small>
                                                         Quantity
                                                         <select #val2 (change)="
                                                         changeQuantity(val2.value, data)
                                                         " value="{{ data.quantity }}" style="
                                                         border-color: #fe9ab3;
                                                         border-radius: 5px;
                                                         padding: 2px;
                                                         margin-left: 5px;
                                                         ">
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                            <option value="9">9</option>
                                                            <option value="10">10</option>
                                                         </select>
                                                      </p>
                                                   </div>
                                                   <div class="col-lg-4 col-md-4 col-sm-4">
                                                      <div class="rupee-part">
                                                         <small class="rupee">₹ {{ data.cost.toFixed(2) }}</small>
                                                         <a *ngIf="!checkDeliveryTime(item)"
                                                            (click)="changeQuantity(0, data)" class="del-a"><i
                                                               class="fa fa-trash" aria-hidden="true"></i>Remove</a>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <br />
                                    <!----------gift category loop end------------>
                                 </div>
                                 <div class="row">
                                    <div class="col-lg-12 text-right">
                                       <span class="totalCst">Total : ₹ {{ item.totalCst.toFixed(2)}}
                                       </span>
                                    </div>
                                    <div class="col-lg-12"
                                       *ngIf="!panProducts[index]?.isPanProduct&&panProducts[index]?.hideAddOns">
                                       <button class="btn btn-spl mb-15" (click)="openAddonPopup(item)">
                                          Make It Extra Special
                                       </button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div>
                           <p *ngIf="validData[index]==false&&!panProducts[index].status==false" class="textstyle">Sorry
                              we
                              can't proceed for
                              delivery.Reached the
                              ordering time limit.
                           </p>
                           <p *ngIf="panProducts[index].status==false" class="textstyle">{{panProducts[index].message}}
                           </p>
                           <a (click)="changeQuantity(0, item)" class="del-a f-right" style="cursor: pointer"><i
                                 class="fa fa-trash" aria-hidden="true"></i>Remove</a>
                        </div>
                     </div>
                  </ng-container>
                  <div class="card p-15 mb-15" *ngIf="ShowHomeAndStore('store')">
                     <div class="row">
                        <div class="col-lg-6">
                           <div class="sub-address">
                              <!-- <h5>Store Address</h5>                         -->
                              <h5>Store Address</h5>
                              <ul *ngIf="getCartData2?.storeAddress?.id != undefined">
                                 <li>Name : {{ getCartData2?.storeAddress?.name }}</li>
                                 <!-- <li> Email :  {{ getCartData?.storeAddress?.email}}</li> -->
                                 <li>Mobile : {{ getCartData2?.storeAddress?.phone }}</li>
                                 <li>{{ getCartData2?.storeAddress?.address }}</li>
                              </ul>
                              <p class="text-center" *ngIf="getCartData2?.storeAddress?.id == undefined">
                                 Store Address Not Found..!
                              </p>
                           </div>
                        </div>
                        <div class="col-lg-6 text-right">
                           <div>
                              <button class="btn add-adr-head" *ngIf="getCartData2?.storeAddress?.id == undefined"
                                 (click)="openAddAddressModal('store')">
                                 Add Address
                              </button>
                              <button class="btn add-adr-head" *ngIf="getCartData2?.storeAddress?.id != undefined"
                                 (click)="openAddAddressModal('store')">
                                 Change Address
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <ng-container *ngFor="let item of customCartData; let index = index">
                     <div class="card p-15 mb-15" *ngIf="item?.attribute?.store == true">
                        <!--<h6 class="ord-detl-h6">Order {{index+1}} </h6>-->
                        <!-- <p>{{validData[index]}}</p> -->
                        <div [ngClass]="(validData[index]==false||panProducts[index].status==false)? 'blurclass' : ''">
                           <!--row start-->
                           <div class="card p-15 mb-15">
                              <div class="row">
                                 <div class="col-lg-12">
                                    <div class="row">
                                       <div class="col-lg-2 col-md-2 col-sm-2">
                                          <a routerLink="/details/{{ item.product.productId }}">
                                             <img *ngIf="!item.product?.productImages.includes('mp4')"
                                                src="{{ imgPth + item.product?.productImages }}" alt="" srcset="" />
                                             <video *ngIf="item.product?.productImages.includes('mp4')"
                                                [src]="imgPth + item.product?.productImages"> </video>
                                          </a>
                                       </div>
                                       <div class="col-lg-6 col-md-6 col-sm-6 pl-0">
                                          <h4>{{ item.product.productName }}</h4>
                                          <div>
                                             <img src="./assets/images/delivery.png" style="
                                                width: 6%;
                                                margin-right: 5px;
                                                margin-top: -5px;
                                                " />
                                             <span class="delon1">Estimated Delivery : </span>
                                             <span class="delon2">{{
                                                item.attribute.deliveryIn
                                                }}</span>
                                          </div>
                                          <p class="classes">
                                             {{ item.pricing.kg }}
                                             <span *ngIf="item.product.isKg">Kg</span>
                                             <span *ngIf="!item.product.isKg">Nos</span>
                                             <small class="verticalLine"></small>
                                             {{ item.pricing.flavour ? item.pricing.flavour : 'NA' }}
                                             <small class="verticalLine" *ngIf="item.attribute.eggType"></small>
                                             {{ item.attribute.eggType ? (item.attribute.eggType | titlecase) : '' }}
                                             <small class="verticalLine"></small>
                                             Quantity
                                             <select #val (change)="changeQuantity(val.value, item)"
                                                value="{{ item.quantity }}" style="
                                             border-color: #fe9ab3;
                                             border-radius: 5px;
                                             padding: 2px;
                                             margin-left: 5px;
                                             ">
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                             </select>
                                          </p>
                                          <p *ngIf="item?.attribute?.message">
                                             Message On Cake : {{ item?.attribute?.message }}
                                          </p>
                                       </div>
                                       <div class="col-lg-1 col-md-1 col-sm-1"></div>
                                       <div class="col-lg-3 col-md-3 col-sm-3">
                                          <div class="rupee-part">
                                             <small class="rupee">₹ {{ item.cost | number: "1.2-2" }}</small>
                                             <a *ngIf="!checkDeliveryTime(item)" (click)="changeQuantity(0, item)"
                                                class="del-a" style="cursor: pointer"><i class="fa fa-trash"
                                                   aria-hidden="true"></i>Remove</a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <!--row end-->
                           <div class="row">
                              <div class="col-lg-12">
                                 <div class="row" *ngIf="item.subItem">
                                    <!----------gift category loop start------------>
                                    <div class="col-lg-12 col-sm-12 col-12" *ngFor="let data of item.subItem">
                                       <div class="card p-15 mb-15">
                                          <div class="row">
                                             <div class="col-lg-12">
                                                <div class="row">
                                                   <div class="col-lg-2 col-md-2 col-sm-2">
                                                      <div class="sub-image-part">
                                                         <a routerLink="/details/{{
                                                            data.product.productId
                                                            }}">
                                                            <img class="sub-img" src="{{
                                                            imgPth + data.product?.productImages
                                                            }}" alt="" srcset="" />
                                                         </a>
                                                      </div>
                                                   </div>
                                                   <div class="col-lg-6 col-md-6 col-sm-6 pl-0">
                                                      <div class="d-flex">
                                                         <h4>{{ data.product.productName }}</h4>
                                                         <span class="addon-label">Addon</span>
                                                      </div>
                                                      <p class="classes">
                                                         {{ data.pricing.kg }}
                                                         <span *ngIf="data.product.isKg">Kg</span>
                                                         <span *ngIf="!data.product.isKg">Nos</span>
                                                         <small class="verticalLine"></small>
                                                         {{ data.pricing.flavour ? data.pricing.flavour : 'NA' }}
                                                         <small class="verticalLine"
                                                            *ngIf="data.attribute.eggType"></small>
                                                         {{ data.attribute.eggType ? (data.attribute.eggType |
                                                         titlecase) : '' }}
                                                         <small class="verticalLine"></small>
                                                         Quantity
                                                         <select #val2 (change)="
                                                         changeQuantity(val2.value, data)
                                                         " value="{{ data.quantity }}" style="
                                                         border-color: #fe9ab3;
                                                         border-radius: 5px;
                                                         padding: 2px;
                                                         margin-left: 5px;
                                                         ">
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                            <option value="9">9</option>
                                                            <option value="10">10</option>
                                                         </select>
                                                      </p>
                                                   </div>
                                                   <div class="col-lg-4 col-md-4 col-sm-4">
                                                      <div class="rupee-part">
                                                         <small class="rupee">₹
                                                            {{ data.cost | number: "1.2-2" }}</small>
                                                         <a *ngIf="!checkDeliveryTime(item)"
                                                            (click)="changeQuantity(0, data)" class="del-a"><i
                                                               class="fa fa-trash" aria-hidden="true"></i>Remove</a>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <br />
                                    <!----------gift category loop end------------>
                                 </div>
                                 <div class="row">
                                    <div class="col-lg-12 text-right">
                                       <span class="totalCst">Total : ₹ {{ item.totalCst | number: "1.2-2" }}
                                       </span>
                                    </div>
                                    <div class="col-lg-12">
                                       <button class="btn btn-spl mb-15" (click)="openAddonPopup(item)">
                                          Make It Extra Special
                                       </button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div>
                           <p *ngIf="validData[index]==false&&!panProducts[index].status==false" class="textstyle">Sorry
                              we can't proceed for delivery.Reached the ordering time limit.
                           </p>
                           <p *ngIf="panProducts[index].status==false" class="textstyle">{{panProducts[index].message}}
                           </p>
                           <a (click)="changeQuantity(0, item)" class="del-a f-right" style="cursor: pointer"><i
                                 class="fa fa-trash" aria-hidden="true"></i>Remove</a>
                        </div>
                     </div>
                  </ng-container>
                  <!-----add to cart => product loop End------->
               </div>
            </div>
         </div>
         <!-------------------------End Left Side----------------------------->
         <!-------------------------Right Side start----------------------------->
         <div class="col-lg-12 col-xl-4 order-main-div">
            <div class="card p-15" *ngIf="cartItemCount > 0">
               <form *ngIf="cartItemCount > 0" autocomplete="off">
                  <div class="card-body p-0">
                     <div class="form-group promo">
                        <div *ngIf="couponList.length>0&&!getCartData2.data.coupon"
                           class="d-flex align-items-center justify-content-between">
                           <label>Have coupon?</label>
                           <label data-toggle="modal" class="coupon_title" data-target="#couponPopup">Available
                              Coupon</label>
                        </div>


                        <div class="input-group" *ngIf="!getCartData2.data.coupon">
                           <input type="text" value="{{ getCartData2.data.coupon }}"
                              [ngClass]="{ 'disable-textBox': getCartData2.data.coupon }" class="form-control coupon"
                              #couponName placeholder="Coupon code" autocomplete="new-couponName" />
                           <span class="input-group-append" *ngIf="!getCartData2.data.coupon">
                              <button (click)="applyCoupon(couponName.value)"
                                 class="btn btn-primary btn-apply coupon-apply">
                                 Apply
                              </button>
                           </span>
                           <!-- <span class="input-group-append" *ngIf="getCartData2.data.coupon">
                              <button (click)="applyCoupon('delete_coupon')" class="btn btn-primary btn-apply coupon">
                                 Remove Coupon
                              </button>
                           </span> -->
                        </div>

                        <div class="coupon_box" *ngIf="getCartData2.data.coupon">
                           <div class="coupon_appliedstyle">
                              <div>
                                 <img src="assets/images/coupon.svg" alt="image">
                              </div>
                              <div>
                                 <h2>
                                    {{getCartData2.data?.costDetails.discountDetails[0].couponCode|trimtext:7:'.'}}
                                 </h2>
                              </div>
                              <div>
                                 <h3>
                                    {{getCartData2?.data.costDetails.discountDetails[0].type==true
                                    ?'₹': ''}}
                                    {{getCartData2.data?.costDetails.discountDetails[0].discountAmount}}{{getCartData2?.data.costDetails.discountDetails[0].type==true
                                    ?'': '%'}}
                                 </h3>
                              </div>
                           </div>
                           <div class="savings">
                              <h4>Coupon Savings</h4>
                              <span>₹ {{getCartData2?.data?.costDetails.discountDetails[0].discount}}</span>
                              <button (click)="applyCoupon('delete_coupon')" type="button">Remove</button>
                           </div>
                        </div>


                        <!---------------   coupon  ------------------->



                     </div>
                  </div>
               </form>
               <div class="order-details card p-15 pb-5" *ngIf="getCartData2">

                  <h6>Order Summary</h6>
                  <div class="row">

                     <!-- Total -->
                     <div class="col-lg-6 col-6">
                        <p class="pull-left">Total Price</p>
                     </div>
                     <div class="col-lg-6 col-6">
                        <small class="pull-right" *ngIf="checkNull(calculations?.total)">
                           ₹ {{calculations?.total.toFixed(2)}}
                        </small>
                     </div>


                     <!-- Discount -->
                     <ng-container>
                        <div class="col-lg-6 col-6">
                           <p class="pull-left">Discount</p>
                        </div>
                        <div class="col-lg-6 col-6">
                           <small class="pull-right">
                              - ₹ {{calculations?.discount.toFixed(2)}}
                           </small>
                        </div>
                     </ng-container>



                     <!-- coupon discount -->
                     <ng-container *ngIf="checkNull(calculations?.coupon)&&calculations?.coupon>0">
                        <div class="col-lg-6 col-6">
                           <p class="pull-left">Coupon</p>
                        </div>

                        <div class="col-lg-6 col-6 pb_5">
                           <p class="free pull-right mt-1">
                              - ₹ {{calculations?.coupon.toFixed(2)}}
                           </p>
                        </div>
                     </ng-container>



                     <!-- Net amount -->
                     <div class="col-lg-6 col-6">
                        <p class="pull-left">Net Amount</p>
                     </div>
                     <div class="col-lg-6 col-6">
                        <small class="pull-right">
                           ₹ {{calculations?.netAmount.toFixed(2)}}
                        </small>
                     </div>


                     <!--Delivery Charge-->

                     <ng-container *ngIf="calculations?.delivery>0">
                        <div class="col-lg-6 col-6 pb_5">
                           <p class="pull-left">Delivery Charge</p>
                        </div>
                        <div class="col-lg-6 col-6">
                           <small class="pull-right">
                              ₹ {{calculations?.delivery?.toFixed(2)}}
                           </small>
                        </div>
                     </ng-container>

                     <!-- <button type="button" class="coupon_popupbtn" >
                       Use Coupon Code
                      </button> -->





                     <!--Midnight Delivery Charge-->

                     <ng-container *ngIf="calculations?.midNight>0">
                        <div class="col-lg-6 col-6 pb_5">
                           <p class="pull-left">Midnight Delivery Charge</p>
                        </div>
                        <div class="col-lg-6 col-6">
                           <small class="pull-right">
                              ₹ {{calculations?.midNight.toFixed(2)}}
                           </small>
                        </div>
                     </ng-container>



                     <!--  data-toggle="modal" data-target="#exampleModal"  -->
                     <!-- <div class="col-lg-12 col-12" *ngIf="cartType=='hasPan'">
                        <button type=" button" (click)="openShipping()" class="shipping_btn">
                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                              class="bi bi-truck" viewBox="0 0 16 16">
                              <path
                                 d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456M12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
                           </svg>
                           Choose a Shipping Company
                        </button>
                     </div> -->
                     <!-- <div class="col-lg-6 col-6" *ngIf="checkNull(deliveryControl.value)">
                        <div class="d-flex align-items-center p_add">
                           <div class="form-check radio_btn">
                              <label class="form-check-label label_clr"
                                 for="flexRadioDefault2">{{(deliveryControl.value).companyName}}</label>
                              <div>
                                 <label for="" class="delivery_date">Delivery by :<span
                                       class="ml-1">{{(deliveryControl.value).expectedOn}}</span></label>
                              </div>
                           </div>
                        </div>
                     </div> -->
                     <!-- <div class="col-lg-6 col-6" *ngIf="checkNull(deliveryControl.value)">
                        <p class=" pull-right  textclr_add" [style.color]="textColor"> ₹
                           {{(deliveryControl.value).deliveryCharge}}</p>
                     </div> -->

                  </div>
               </div>
               <form *ngIf="cartItemCount > 0" autocomplete="off">
                  <div class="card-body p-0">

                     <div>

                        <!-- <div class="coupon-list-div" *ngFor="let item of getCouponList">
                           {{ item.couponCode
                           }}<span class="apply-coupon-text" (click)="applyCoupon(item.couponCode)">Apply Coupon</span>
                        </div> -->

                     </div>
                  </div>


                  <div class="card p-15 grand-total " style="margin-top:-2px;">
                     <div class="row">
                        <div class="col-6">
                           <h5 style="font-size:18px !important ; font-weight: 500 !important;"
                              class="pull-left  fnt-14">Grand
                              Total
                           </h5>
                        </div>
                        <div class="col-6">
                           <p class="pull-right" style="font-size:15px !important ; font-weight: 500 !important;">
                              ₹ {{calculations?.grandTotal?.toFixed(2)}}

                           </p>
                        </div>
                     </div>
                  </div>


                  <div class="pay">
                     <button type="button" *ngIf="calculations?.grandTotal>0" (click)="checkPincode()"
                        class="btn btn-custom">
                        Continue to Pay
                     </button>
                  </div>
               </form>
               <br />
               <div class="row safe-secure">
                  <div class="col-lg-12 pr-0 secure-column">
                     <img src="assets/images/ssl.jpg" alt="" srcset="" />
                     <p>100% Safe & Secure Transactions</p>
                  </div>
                  <!--<div class="col-lg-10 ">
                     <p>100% Safe & Secure Transactions</p>
                     </div>-->
               </div>
            </div>
         </div>
         <!-----------------------------Right Side End--------------------------------->
      </div>
   </div>
   <!--main row and container End-->
   <div class="whatsapp-icon" style="background-color: #22ce5a">
      <a href="https://api.whatsapp.com/send?phone=+918122210222 &amp;text= I'm interested in this product and I have a few questions. Can you help?"
         target="_blank">
         <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path
               d="M10.8039 0.0681698C6.25549 0.756887 2.54823 3.69142 0.901889 7.90756C-0.353895 11.1236 -0.185656 14.8007 1.35253 17.9149L1.71304 18.6396L0.841803 21.8077C0.367129 23.5504 -0.0174167 24.9877 0.00060889 24.9997C0.012626 25.0117 1.48472 24.6404 3.27526 24.1733L6.53188 23.3228L7.19282 23.6343C10.6297 25.2393 14.5833 25.2213 17.9661 23.5804C19.5464 22.8138 20.9704 21.6939 22.13 20.3105C24.1609 17.891 25.2064 14.639 24.9661 11.5069C24.6236 7.04517 22.0039 3.1644 18.0262 1.23C16.8966 0.679031 15.9533 0.3736 14.6314 0.12207C13.9164 -0.00968513 11.555 -0.045618 10.8039 0.0681698ZM14.343 2.18822C15.3164 2.35591 16.2537 2.67332 17.2151 3.15841C18.2906 3.7034 19.0176 4.23041 19.8588 5.06885C21.7875 6.99127 22.797 9.31494 22.9051 12.0459C22.9893 14.3156 22.3463 16.4656 21.0004 18.382C20.4236 19.2085 19.2039 20.3943 18.3567 20.9513C16.5842 22.1311 14.9138 22.6641 12.8288 22.718C10.7018 22.7779 9.13358 22.3766 7.08467 21.2327L6.83832 21.095L4.93962 21.5921C3.90015 21.8676 3.01689 22.0832 2.98685 22.0712C2.95681 22.0592 3.1611 21.2267 3.43749 20.2206C3.70787 19.2145 3.9362 18.3581 3.9362 18.3102C3.9362 18.2623 3.83405 18.0646 3.70186 17.861C2.06754 15.2798 1.68901 11.9321 2.70445 9.02149C3.7259 6.11091 5.95506 3.81718 8.86319 2.69727C9.68636 2.37986 10.5215 2.19421 11.7473 2.05048C12.1979 1.99658 13.676 2.07443 14.343 2.18822Z"
               fill="white" />
            <path
               d="M7.81182 6.69762C7.3732 6.8174 6.70626 7.61392 6.39381 8.39247C6.26763 8.70987 6.25562 8.84762 6.25562 9.59023C6.25562 10.4107 6.26162 10.4407 6.47192 11.0216C7.0908 12.6745 8.96546 14.9742 10.8221 16.3636C11.8135 17.1062 12.6908 17.5434 14.1929 18.0465C15.5088 18.4837 16.374 18.4717 17.3353 17.9986C18.0263 17.6572 18.525 17.1601 18.6813 16.6571C18.8315 16.1839 18.8795 15.4653 18.7714 15.3335C18.6031 15.1299 16.0435 13.9321 15.7791 13.9321C15.5748 13.9321 15.5869 13.9202 14.986 14.6748C14.4272 15.3695 14.247 15.5491 14.0847 15.5491C13.8444 15.5491 12.7929 15.0341 12.162 14.6209C11.8435 14.4053 11.3569 14.022 11.0865 13.7704C10.2933 13.0278 9.40409 11.8121 9.40409 11.4707C9.40409 11.3689 9.52426 11.1833 9.77061 10.9018C10.1552 10.4646 10.4255 10.0095 10.4255 9.79984C10.4255 9.68007 9.57232 7.55403 9.344 7.10487C9.28391 6.99108 9.18778 6.85333 9.12769 6.79943C8.98349 6.67367 8.14229 6.60779 7.81182 6.69762Z"
               fill="white" />
         </svg>
      </a>
   </div>
</section>
<!------------------------------------- Modal Address Section Start ---------------------------->
<div class="modal fade" id="addAddressModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
   aria-hidden="true">
   <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" *ngIf="deliveryType == 'door'">
               Delivery Addresses
            </h5>
            <h5 class="modal-title" *ngIf="deliveryType == 'store'">
               Store Locator
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body" style="background-color: #fff !important" id="checkout">
            <br />
            <!--saved address-->
            <!-- <div class=""></div> -->
            <div class="card delivery-addresses" *ngIf="addressListDiv&&addressList?.length>0">
               <div class="row" *ngFor="let item of addressList; let i = index">
                  <div class="col-lg-12">

                     <label class="form-check-label radio">
                        <!-- "zeroLength" | "hasnoPan" | "hasPan" | "hasboth" | "error" -->
                        <input type="radio" [disabled]="(cartType=='hasPan'&&item?.deliveryId!=0)
                        ||(cartType=='hasnoPan'&&item?.deliveryId==0)" (change)="addressNodeAdd($event,item)"
                           [checked]="i==ids" class="form-check-input" name="address" id="address" [value]="item.id" />

                        <h5>
                           {{ item.name
                           }}<small class="verticalLine" *ngIf="item.phoneNumber"></small>
                           {{ item.phoneNumber }}
                           <small class="verticalLine" *ngIf="item.alternativePhoneNumber"></small>
                           {{ item.alternativePhoneNumber }}
                        </h5>
                        <p>
                           {{ item.email }}<br *ngIf="item.email" />
                           {{ item.line1 }}, {{ item.city }} <br />
                           {{ item.state }} - {{ item.pinCode }}
                           <span class="float-right">
                              <i (click)="editAddress(item)" class="fa fa-pencil mr-10 address-edit-icon"></i>
                              <i (click)="deleteAddress(item.id)" class="fa fa-trash mr-10 addres-del"></i>
                           </span>
                        </p>
                        <span
                           *ngIf="(cartType=='hasPan'&&item?.deliveryId==0)||(cartType!='hasPan'&&item?.deliveryId!=0)"
                           class="checkround"></span>
                     </label>
                  </div>
               </div>
            </div>
            <div class="card delivery-addresses" *ngIf="addressList?.length==0&& !showPickUpStorediv">
               <p style="text-align: center;font-size: 20px;font-weight: 800;">No Delivery Address Found</p>
            </div>
            <!--        
               <div  class="card delivery-addresses" *ngIf="addressListDiv">
                 <h4 style="text-align: center;"> No Shipping Address Found....</h4>
               </div> -->
            <!--store address list-->
            <div *ngIf="showPickUpStorediv" class="card delivery-addresses">
               <b class="text-center" style="color: #fd3568">Select your Near by Store for Pickup</b><br />
               <div class="row" *ngFor="let item of storeaddress; let i = index">
                  <div class="col-lg-12 mt-0.2">
                     <label class="form-check-label radio">
                        <input [checked]="item?.id==deliveryData?.storeAddress?.id" type="radio" name="address"
                           (change)="addressNodeAdd($event,item)" class="form-check-input" id="address"
                           [value]="item.id" />
                        <h5>
                           <b>{{ item.name }}</b>, {{ item.address }}
                        </h5>
                        <p>Phone : {{ item.phone }}</p>
                        <span class="checkround"></span>
                     </label>
                     <br />
                  </div>
               </div>
            </div>
            <!--------------Add or edit address form ---------------->
            <!--------------Add or edit address form End---------------->
         </div>
         <div class="modal-footer">
            <div class="row">
               <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                  <!-- <button type="button" class="btn btn-primary modal-add-address" (click)="onSelectAddress()">Submit</button> -->
                  <button style="font-size: 15px" class="add-address-btn btn-lg addrs-btn"
                     *ngIf="deliveryType == 'door'" (click)="addNewAddress()">
                     Add New Address
                  </button>
               </div>
               <div class="col-6 col-sm-6 col-md-6 col-lg-6 text-right">
                  <button style="font-size: 15px" class="btn modal-add-address addrs-btn add-address-btn"
                     [disabled]="(showPickUpStorediv&&storeaddress?.length<1)||(addressListDiv&&addressList?.length<1)"
                     (click)="onSelectAddress(true)">
                     Submit
                  </button>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>


<div class="modal fade" id="addNewAddressModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
   aria-hidden="true">
   <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title">
               {{ isAddressEdited == true ? "Edit Address" : "Add Address" }}
            </h5>
            <button type="button" class="close" (click)="backListAddress()" aria-label="Close">
               <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body modal_checkout" style="background-color: #fff !important" id="checkout">
            <br />
            <div class="form_wrapper">
               <form [formGroup]="addressForm">
                  <div class=" address-book mb-2 mt-2" style="background: #fff">
                     <h4>
                        {{
                        isAddressEdited == true
                        ? "Edit Delivery Address"
                        : "Add Delivery Address"
                        }}
                     </h4>
                     <div class="container">
                        <div class="row">
                           <div class="col-lg-6">
                              <div class="input-group">
                                 <input type="text" formControlName="name" class="form-control" placeholder="Name"
                                    aria-label="Username" aria-describedby="basic-addon1" />
                              </div>
                              <div *ngIf="submitted2 && a.name.errors" style="color: red">
                                 <div *ngIf="a.name.errors.required">Name is required</div>
                              </div>
                           </div>
                           <div class="col-lg-6">
                              <div class="row">
                                 <div class="col-lg-4 col-4 col-4">
                                    <select name="country" class="form-control">
                                       <option data-code="+91" value="ind">+91</option>
                                    </select>
                                 </div>
                                 <div class="col-lg-8 col-8">
                                    <input type="text" formControlName="phoneNumber" placeholder="Mobile number"
                                       class="form-control" autocomplete="new-phoneNumber" />
                                    <div *ngIf="submitted2 && a.phoneNumber.errors" style="color: red">
                                       <div *ngIf="a.phoneNumber.errors.required">
                                          Phone Number is required
                                       </div>
                                       <div *ngIf="a.phoneNumber.errors.pattern">
                                          Enter Valid 10 digit Mobile Number
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="row pt-3">
                           <div class="col-lg-6">
                              <div class="input-group">
                                 <input type="text" formControlName="email" class="form-control" placeholder="Email"
                                    aria-label="Username" aria-describedby="basic-addon1" autocomplete="new-email" />
                              </div>
                              <div *ngIf="submitted2 && a.email.errors" style="color: red">
                                 <div *ngIf="a.email.errors.required">Email is required</div>
                                 <div *ngIf="a.email.errors.email">Enter Valid Email</div>
                              </div>
                           </div>
                           <div class="col-lg-6">
                              <div class="input-group">
                                 <input type="text" formControlName="alternativePhoneNumber"
                                    placeholder="Alternative mobile number (optional)" class="form-control"
                                    autocomplete="new-alternativePhoneNumber" />
                              </div>
                              <div *ngIf="submitted2 && a.alternativePhoneNumber.errors" style="color: red">
                                 <div *ngIf="a.alternativePhoneNumber.errors.pattern">
                                    Enter Valid 10 digit Mobile Number
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-lg-12">
                              <label for="exampleFormControlTextarea1">Address</label>
                              <textarea name="" formControlName="line1" placeholder="Type your text"
                                 class="form-control" id="" cols="3" rows="3" autocomplete="off"></textarea>
                              <div *ngIf="submitted2 && a.line1.errors" style="color: red">
                                 <div *ngIf="a.line1.errors.required">Address is required</div>
                              </div>
                           </div>
                        </div>
                        <div class="row pt-3">
                           <div class="col-lg-6">
                              <label for="state">State</label>
                              <div class="input-group">
                                 <select class="form-control placeholder" [value]="" placeholder="State" #state
                                    formControlName="state" (change)="stateChanged(state?.value)"
                                    aria-describedby="basic-addon1">
                                    <option hidden value="" disabled selected>Select State</option>
                                    <!-- <option value="" selected>Your Placeholder Text</option> -->
                                    <option [value]="sts" *ngFor="let sts of states">{{sts|titlecase}}</option>
                                 </select>
                              </div>
                              <div *ngIf="submitted2 && a.state.errors" style="color: red">
                                 <div *ngIf="a.state.errors.required">State is required</div>
                              </div>
                           </div>
                           <div class="col-lg-6">
                              <label for="district"> District/City</label>
                              <div class="input-group">
                                 <!-- <input type="text" class="form-control" onkeypress="(event.charCode > 64 && 
                                    event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 32"
                                    formControlName="city" placeholder="City" aria-describedby="basic-addon1" /> -->
                                 <select class="form-control placeholder" title="Select city/district"
                                    formControlName="city" aria-describedby="basic-addon1">
                                    <option hidden value="" disabled selected>Select District/City</option>
                                    <option [value]="dst" *ngFor="let dst of districts">{{dst|titlecase}}</option>
                                 </select>
                              </div>
                              <div *ngIf="submitted2 && a.city.errors" style="color: red">
                                 <div *ngIf="a.city.errors.required">City is required</div>
                              </div>
                           </div>
                        </div>
                        <div class="row pt-3">
                           <div class="col-lg-6">
                              <div class="details">
                                 <div class="ng-autocomplete" style="width: 110%; border: 1px solid #ced4da">
                                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                                    <ng-autocomplete [data]="pinCodeData" [searchKeyword]="pinCodeKeyword"
                                       placeholder="Enter pincode or city" notFoundText="Not found"
                                       (inputChanged)="onChangePinCodeSearch($event);setPanPin($event)"
                                       (inputCleared)="resetControl($event)" (selected)="setPanPin($event)"
                                       [itemTemplate]="itemTemplate1" formControlName="deliveryId">
                                    </ng-autocomplete>
                                    <ng-template #itemTemplate1 let-item>
                                       <a [innerHTML]="item.searchKey"></a>
                                    </ng-template>
                                    <!-- <ng-template #notFoundTemplate1 let-notFound>
                                       <div style="color: #fc98b2;text-align: center;font-weight: 500;"
                                        >
                                         Pincode that you have entered will eligible for only PAN India products.
                                       </div>
                                       </ng-template> -->
                                 </div>
                                 <div *ngIf="submitted2 && a.deliveryId.errors" style="color: red">
                                    <div *ngIf="a.deliveryId.errors.required">
                                       Pincode is required
                                    </div>
                                    <div *ngIf="a.deliveryId.errors.pattern">
                                       Enter Valid 6 digit pincode
                                    </div>
                                 </div>
                                 <div class="danger" style="
                                    color: #a40b34;
                                    font-weight: 500;
                                    margin-top: 10px;" *ngIf="notAvailable">
                                    Delivery is not available to this area.
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </form>
            </div>
         </div>
         <div class="modal-footer">
            <div class="row">
               <div class="col-lg-6">
                  <button type="button" class="btn btn-primary modal-add-address mr-3" (click)="saveAddress()">
                     {{ isAddressEdited == true ? "Edit Address" : "Add Address" }}
                  </button>
                  <button type="button" class="btn btn-custom" (click)="backListAddress()">
                     Back
                  </button>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<!------------------------------------- Modal Address Section End ---------------------------->
<!----------------- Make it extra special Modal --------------------------->
<div class="modal fade" id="addonsPopupModal2" data-keyboard="false" data-backdrop="static" tabindex="-1" role="dialog"
   aria-labelledby="exampleModalLabel" aria-hidden="true" style="padding-right: auto !important">
   <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
               Make it Extra Special!
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">
            <h6 class="text-center mb-20">Frequently Bought Add-Ons</h6>
            <div class="frequently-card">
               <div *ngIf="getdata">
                  <div class="row">
                     <div class="col-md-4" *ngFor="let item of getdata;let i=index">
                        <div class="card box-shadow mb-20">
                           <a>
                              <img class="card-img-top" src="{{ imgPth + item.productImages }}"
                                 data-holder-rendered="true" />
                           </a>
                           <div class="card-body">
                              <div class="row d-flex justify-content-between align-items-center">
                                 <!--pricing Section-->
                                 <div class="col-lg-10 col-sm-10">
                                    <p class="name">{{ item.productName | titlecase }}</p>
                                    <p class="price">
                                       <span class="dis-price">
                                          ₹ {{ item?.productDetails?.pricing[0]?.disPrice }}
                                       </span>
                                       <del>
                                          ₹ {{ item?.productDetails?.pricing[0]?.price }}
                                       </del>
                                    </p>
                                 </div>
                                 <div class="col-lg-2 col-sm-12 mt-2 mbottom-10">
                                    <i class="fa fa-minus min-crt" [ngClass]="{'disbl-minus': item.quantity === 0 }"
                                       (click)=clickCrtMinus(item) aria-hidden="true"></i>
                                    <input type="text" class="txtbx-crt" value="{{item.quantity}}">
                                    <i class="fa fa-plus plus-crt" (click)=clickCrtPlus(item) aria-hidden="true"></i>
                                    <br>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <br />
            <!-- <div class="row">
               <div class="col-lg-8">
               </div>
               <div class="col-lg-4 prc-dtl">
                 <b>Price Details: </b><br>
                 <span class="prc-spn">1 Base Item </span> <span class="prc-spn2"> <i class="fa fa-inr"></i>
                   {{mainProductPrice}} </span><br>
                 <span class="prc-spn">{{cartData.length - 1 }} Addons </span> <span class="prc-spn2"> <i
                     class="fa fa-inr"></i> {{addonPrice}} </span> <br>
                 <span class="prc-spn">Total Amount</span> <span class="prc-spn2"> <i class="fa fa-inr"></i> {{ totalPrice }}
                 </span>
               </div>
               </div> -->
         </div>
         <!--modal body end-->
         <div class="modal-footer">
            <div class="row">
               <div class="col-lg-12">
                  <div class="row">
                     <div class="col-lg-2 price-detail pl-0">
                        <h6>price details</h6>
                     </div>
                     <div class="col-lg-8">
                        <div class="row mt-5 " style="margin-bottom: 10px;">
                           <div class="me-2 add-base-column">
                              <div class="add-base-item  mright-4">
                                 <p class="fnt-11">1 Base Item</p>
                                 <span class="fnt-11"><i class="fa fa-inr fnt-11" aria-hidden="true"></i> {{
                                    mainProductPrice
                                    }}</span>
                              </div>
                              <span class="ml-10 fnt-11">+</span>
                           </div>
                           <div class="me-2  add-base-column">
                              <div class="add-base-item  mright-4">
                                 <p class="fnt-11">{{cartData.length - 1 }} Add-ons</p>
                                 <span><i class="fa fa-inr fnt-11" aria-hidden="true"></i> {{addonPrice}}</span>
                              </div>
                              <span class="ml-10 fnt-11">+</span>
                           </div>
                           <div class="me-2  add-base-column">
                              <div class="add-base-item   mright-4">
                                 <p class="fnt-11">Delivery</p>
                                 <span class="fnt-11">Free</span>
                              </div>
                           </div>
                           <div class="me-2">
                              <div class="add-base-column">
                                 <span class="ml-10">=</span>
                                 <div class="ml-10 total-column">
                                    <p class="fnt-11">Total</p>
                                    <span class="all-total fnt-11" style="color:red;"><i
                                          class="fa fa-inr tot-inr fnt-11" aria-hidden="true"></i>
                                       {{ totalPrice }}</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row justify-content-end">
               <div class="col-lg-12addon-btn">
                  <button type="button" *ngIf="cartData.length == 1" (click)="submitAddToCart()"
                     class="mdl-footer">Continue
                     Without Addons</button>
                  <button type="button" *ngIf="cartData.length > 1" (click)="submitAddToCart()"
                     class="mdl-footer">Continue
                     With
                     {{cartData.length - 1 }}
                     Addons</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<!--------------------End Make it extra special Modal ----------------------------->



<!-- [style.color]="textColor" -->
<!-- ---------------------Delivery Partners Modal ---------------------------------->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
   aria-hidden="true">
   <div class="modal-dialog" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title color_white" id="exampleModalLabel">Shipping Options</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body card_shadow">
            <div class="row">

               <ng-container *ngFor="let data of deliveryPartners;let i = index">
                  <div class="col-lg-6 col-6 border_style d-flex">
                     <div class="d-flex align-items-center mt-1 p_add">
                        <div class="form-check radio_btn">
                           <input class="form-check-input" #radio [formControl]="deliveryControl" [value]="data"
                              type="radio" name="flexRadioDefault" [id]="i">
                           <label
                              [style.color]="checkNull(deliveryControl.value)&&(deliveryControl.value==data) ? textColor :''"
                              class="form-check-label label_clr" for="flexRadioDefault1">{{data?.companyName}}<span
                                 class="ml-1">( Delivery By {{data?.expectedOn}} )</span></label>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6 col-6 border_style amount-option">
                     <p class="pull-right textclr_add"
                        [style.color]="checkNull(deliveryControl.value)&&(deliveryControl.value==data) ? textColor :''">
                        ₹
                        {{data?.deliveryCharge}}
                     </p>
                  </div>


               </ng-container>



            </div>
            <div *ngIf="deliveryPartners?.length==0" class=""
               style="text-align: center;font-weight: 500;color: #fc98b2;">
               Delivery is not available for this address
            </div>
         </div>
         <div *ngIf="deliveryPartners?.length!=0" class="modal-footer bg_clr text-right">
            <button type="button" class="btn btn-primary ship_cart_btn" (click)="selectPartner()">Submit</button>
         </div>
      </div>
   </div>
</div>


<!--------------   Coupon design popup  ------------------->
<div class="modal fade" id="couponPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
   aria-hidden="true">
   <div class="modal-dialog" role="document">
      <div class="modal-content modal_widthset">
         <div class="modal-header">
            <h5 class="modal-title clr_white" id="exampleModalLabel">Apply Coupon Code</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body coupon_header">
            <label class="sr-only" for="inlineFormInputGroupUsername2">Search</label>
            <div class="input-group mb-2 ">
               <div class="input-group-prepend">
                  <div class="input-group-text bg_none"><i class="fa fa-search"></i></div>
               </div>
               <input [formControl]="searchControl" type="text" class="form-control border-rnone"
                  id="inlineFormInputGroupUsername2" placeholder="Search">
            </div>



            <ng-container *ngFor="let data of searchedCouponList;let inx = index;">
               <div class="coupon_search mt-0">
                  <div class="d-flex align-items-center justify-content-between">
                     <div class="d-flex align-items-center">
                        <div>
                           <img class="coupon_icon" src="assets/images/coupon.svg" alt="image">
                        </div>
                        <div class="offer_card">
                           <div class="d-flex align-items-center">
                              <h1>{{data?.couponName|trimtext:7:'.'}}<span class="offer_text">{{data?.discountAmount}}
                                    OFF</span></h1>

                           </div>


                           <h4>Coupon Code&nbsp;:&nbsp;<span>{{(data?.couponCode|uppercase)|trimtext:7:'.'}}</span></h4>
                           <h2>Min ₹{{data?.minimum}}</h2>
                        </div>
                     </div>


                     <div class="coupon_btn">
                        <button (click)="applyCoupon(data.couponCode)" type="button">Apply</button>
                     </div>
                  </div>
               </div>

            </ng-container>

            <ng-container *ngIf="searchedCouponList?.length==0">
               <div class="" style="display: flex;justify-content: center;align-items: center;">
                  <span> No Coupon Records Found</span>
               </div>

            </ng-container>


         </div>

      </div>
   </div>
</div>