import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { checkNull } from "../product-details/date-and-time-validations";
import { AppConstants } from "src/app/configuration/app.constants";

@Component({
  selector: "app-shareable",
  templateUrl: "./shareable.component.html",
  styleUrls: ["./shareable.component.less"],
})
export class ShareableComponent implements OnInit {
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.findRouter();
  }

  findRouter() {
    console.log(this.router.url);

    let url = this.router.url

    let params = url.split("/");
    console.log(url, "this is the errrror URL");
    // alert(params)
    if (params[1] == "sharable") {
      let id = checkNull(params[2]) ? params[2]?.split("?")[0] : "";
      if (checkNull(id)) {
        let baseUrl =
          AppConstants.SERVER_CONFIG.USE_URL == "STAGING"
            ? AppConstants.SERVER_CONFIG.STAGING
            : AppConstants.SERVER_CONFIG.PRODUCTION;
        let url = `${baseUrl}/share/${id}?isBot=false`;
        location.href = url;
      }

      // else {
      //   if (["Android", "iOS", "Windows Phone"].includes(this.detectDeviceType())) {
      //     location.href = "https://mblpuppysbakery.technogenesis.in/";
      //   } else {
      //     // this.router.navigate(['/']);
      //   }
      // }
    }
  }

  detectDeviceType(): string {
    const userAgent = navigator.userAgent;

    // Check for common user agent strings to identify device type
    if (userAgent.match(/Android/i)) {
      return "Android";
    } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
      return "iOS";
    } else if (userAgent.match(/Windows Phone|Windows Mobile/i)) {
      return "Windows Phone";
    } else if (userAgent.match(/Windows NT/i)) {
      return "Windows PC";
    } else if (userAgent.match(/Macintosh/i)) {
      return "Mac";
    } else if (userAgent.match(/Linux/i)) {
      return "Linux";
    } else {
      return "Unknown";
    }
  }
}
