import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { AppConstants } from "src/app/configuration/app.constants";

@Component({
  selector: "global-search-template",
  templateUrl: "./global-search-template.component.html",
  styleUrls: ["./global-search-template.component.less"],
})
export class GlobalSearchTemplateComponent implements OnInit, OnChanges {
  @Input() searchedData: any;
  @Output() navigatedEvent: EventEmitter<any> = new EventEmitter();
  baseURL = AppConstants.SERVER_CONFIG.PRODUCTION;
  constructor(private router: Router) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.searchedData, "searched data");
  }

  navigate(type: "category" | "product", data) {
    this.navigatedEvent.emit(data);
    if (type == "category") {
      this.router.navigate([
        `/category/${data?.categoryName.replace("/", " ")}-${data?.categoryId}`,
      ]);
    } else {
      this.router.navigate([
        `/details/${
          data.productName.replace(/\s+/g, "-").toLowerCase() + "-" + data.productId
        }`,
      ]);
    }
  }
}
